//////////////////////////////////////////////////////////////////////////////////////
// Setup: Please edit this section only
//////////////////////////////////////////////////////////////////////////////////////
/**
*User defined event names:
*
*Define names of events you want to track and pass as second generic parameter to AnalyticsEvent class
*/
export enum AnalyticsEventName {
    DASHBOARD_GRAPHS = "dashboard_graphs",
    INSIGHTS = "insights",
    SETTINGS = "settings",
    USER_LOGIN = "user_login",
    USER_LOGOUT = "user_logout",
    MENU_LINK = "menu_link",
    CLOSE_WINDOW = "close_window",
    HOME_LINK = "home_link",
    NOTIFICATIONS = "notifications",
} 
//////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////

/**
 * Static event tags required by API
 */
export enum AnalyticsTag {
    AUTHENTICATION = "authentication",
    CLICK = "click",
    VIEW = "view",
    INTERACTION = "interaction",
    ERROR = "error",
    CUSTOM = "custom"
}

/**
 * Static event platforms required by API
 */
export enum AnalyticsPlatform {
    WATCH = "watch",
    MOBILE = "mobile",
    DASHBOARD = "dashboard",
}

/**
 * Event DTO required by API
 */
export interface AnalyticsEventDTO<Params, AnalyticsEventName> {
    tag: AnalyticsTag
    platform_version: string
    platform: AnalyticsPlatform
    name: AnalyticsEventName
    params?: Params
    resolution?: {width: number, height: number}
    timeZone?: string
    readMe?: string
}

// Basic Params type implementations required on AnalyticsEvent class
/**
 * All Params are wrapped in AnalyticsParams by AnalyticsEvent.Save function
 */
type AnalyticsParams<Params> = {params: Params & CustomParams}
/**
 * All Params have custom option for arbitrary properties
 */
type CustomParams = {custom?: any}
/**
 * Params for click events
 */
export type ClickParams = {page: string, button: string, action?: string}
export type SelectionParams = {key: string | number, value: string | number}
export type ViewParams = {page: string, section?: string}
export type DateParams = {from?: number, to?: number}
export type GraphParams = {graph: string}
export type ErrorParams = {code: number | string, message?: string}
export type NotificationTableParams = ClickParams & {page_size?: number, field?: string, sort?: string}

export class AnalyticsEvent<Params, AnalyticsEventName> {
    private readonly event: AnalyticsEventDTO<Params, AnalyticsEventName>
    private readonly access_token: string
    private readonly events_endpoint: string
    private readonly PostFunc: (e: AnalyticsEventDTO<Params, AnalyticsEventName>) => Promise<any>
    
    constructor(args: {
        event: AnalyticsEventDTO<Params, AnalyticsEventName>,
        access_token?: string,
        events_endpoint?: string,
        PostFunc?: (e: AnalyticsEventDTO<Params, AnalyticsEventName>) => Promise<any>
    }) {
        if(!args.PostFunc && (!args.access_token || !args.events_endpoint)) {
            throw new Error("AnalyticsEvent must be initialized with PostFunc or both access_token and events_endpoint")
        }
        this.event = args.event
        this.PostFunc = args.PostFunc ? args.PostFunc : this.Post
        this.access_token = args.access_token!
        this.events_endpoint = args.events_endpoint!
    }

    private Post = async(e: AnalyticsEventDTO<Params, AnalyticsEventName>) => {
        var headers = {
            Authorization: `Bearer ${this.access_token}`,
            'Content-Type': 'application/json'
        } 
        return fetch(this.events_endpoint + `/events/${e.tag}`,
           {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(e)
           }
           )
           .then(res => {
                return res.json()
           })
    }

    async Save(params?: AnalyticsParams<Params>) {
        try {
            await this.PostFunc({...this.event, ...params})
            .then(res => console.log(res))   
        } catch (error) {
            console.log(error)
        }
    }
}