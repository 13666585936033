import { useEffect, useState } from "react";
import { Participant } from "../../interfaces/Participant";
import { Roles, useParticipants, useRoles } from "../../redux/hooks";
import { selectParticipant } from "../../redux/participants/participantsHelpers";
import { ComboBox, Options } from "./ComboBox"
import { sortOptionsAlphabetically } from "./utils";

interface Props {
    callback: (id: string) => void
    disabled?: boolean
    style?: object
}

export const ManagerParticipantSelection: React.FC<Props> = ({callback, disabled, style}) => {
    const { allParticipants } = useParticipants();
    const [dropDownOptions, setDropDownOptions] = useState<Options[]>([{label: "None", value: "none"}]);
    const [careTeamAccess] = useRoles(Roles.careteam);

    // Sort participant data into options for combo box
    useEffect(() => {
        if(allParticipants.data) {
            let options: Options[] = allParticipants.data.reduce((acc: any[], participant: Participant) => {
                    acc.push({
                        label: participant.description,
                        value: participant.id
                    })
                    return acc
                },[])
            options = sortOptionsAlphabetically(options)
            setDropDownOptions(options)
        }
    },[allParticipants.data])

    const selectDropDown = (selection: Options | null) => {
        if(selection?.value) {
            callback(selection.value);
            selectParticipant(selection.value, false);
        }
    }

    return (
        <>
        {
            careTeamAccess &&
            <div style={style}>
                <ComboBox
                    label={'Participant'}
                    options={dropDownOptions} 
                    callback={selectDropDown}
                    disabled={disabled}
                />
            </div>
        }
        </>
    )
}