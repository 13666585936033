export const DB_ENDPOINT = process.env.REACT_APP_DB_ENDPOINT as string
export const REPORT_DB_ENDPOINT = process.env.REACT_APP_REPORT_DB_ENDPOINT as string
export const AUTH_ENDPOINT = process.env.REACT_APP_AUTH_ENDPOINT as string
export const BASE_PATH = process.env.REACT_APP_BASE_PATH as string
export const VISUALIZATION_ENDPOINT = process.env.REACT_APP_DATA_VISUALIZATION_ENDPOINT as string
export const ANALYTICS_ENDPOINT = process.env.REACT_APP_ANALYTICS_ENDPOINT as string
export const VERSION = process.env.REACT_APP_VERSION as string
export const INSIGHTS_ENDPOINT = process.env.REACT_APP_INSIGHTS_ENDPOINT as string

if(
    !DB_ENDPOINT            || 
    !REPORT_DB_ENDPOINT     ||
    !INSIGHTS_ENDPOINT      ||
    !AUTH_ENDPOINT          || 
    !BASE_PATH              ||
    !VISUALIZATION_ENDPOINT ||
    !ANALYTICS_ENDPOINT     ||
    !VERSION
) {
    throw new Error("Environment variables not set.")
}