import { Checkbox, FormControlLabel, FormGroup, TextField } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import { 
    emailWarning, 
    passwordsNotEqual, 
    passwordWarning, 
    validateEmail, 
    validatePassword, 
    validatePasswordsEqual 
} from "../../validation/Validate";
import { useTokens } from "../../redux/hooks";
import { changeUserPassword, updateUser } from "../../redux/users/usersHelpers";
import { AcceptModal } from "../../components/Modals/AcceptModal";
import { AcceptRejectModal } from "../../components/Modals/AcceptRejectModal";
import { logOut } from "../../redux/helpers";
import { AnalyticsTag, AnalyticsEventName, ClickParams } from "../../analytics";
import { CreateAnalyticsEvent } from "../../redux/analytics/analyticsHelpers";

export const Settings: React.FC = () => {
    const { username, user_id } = useTokens().tokenData
    const [email, setEmail] = useState(username);
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [previousPassword, setPreviousPassword] = useState('');
    const [passwordsEqual, setPasswordsEqual] = useState(false);
    const [warningOpen, setWarningOpen] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');
    const [changePass, setChangePass] = useState(false);
    const clickEvent = CreateAnalyticsEvent<ClickParams>(AnalyticsEventName.SETTINGS, AnalyticsTag.CLICK)

    const handleChange = (field: string, value: string) => {
        switch(field) {
            case 'email':
                setEmail(value);
                return;
            case 'newPassword':
                setNewPassword(value);
                setPasswordsEqual(validatePasswordsEqual(value, repeatPassword))
                return;
            case 'repeatPassword':
                setRepeatPassword(value);
                setPasswordsEqual(validatePasswordsEqual(newPassword, value))
                return;
            case 'previousPassword':
                setPreviousPassword(value);
                return;
        }
    }

    const handleSubmit = () => {
        if(email && !validateEmail(email)) {
            setWarningMessage(emailWarning);
            setWarningOpen(true);
            return;
        }
        if(changePass && !validatePasswordsEqual(newPassword, repeatPassword)) {
            setWarningMessage(passwordsNotEqual);
            setWarningOpen(true);
            return;
        }
        if(changePass && (!validatePassword(newPassword) || !validatePassword(repeatPassword))) {
            setWarningMessage(passwordWarning);
            setWarningOpen(true);
            return;
        }
        if(email && username && user_id && (email !== username)) {
            clickEvent.Save({
                params: {page: 'settings', button: "update_settings", action: "update_user"}
            })
            updateUser({
                id: user_id,
                email: email
            }).then(() => logOut())
        }
        if(changePass) {
            clickEvent.Save({
                params: {page: 'settings', button: "update_settings", action: "change_password"}
            })
            changeUserPassword({
                newPassword: newPassword,
                previousPassword: previousPassword
            })
        }
    }

    return (
            <div className="form-page-wrapper">
                <AcceptModal 
                    message={warningMessage}
                    isOpen={warningOpen}
                    callBack={() => setWarningOpen(false)}
                />
                <div className='form-wrapper'>
                    <h2 className="form-header">Settings</h2>
                    <p className="form-description">
                        Fill out the fields below and click 'Update Settings'.
                    </p>
                        <TextField
                            required
                            type="email"
                            label="Email"
                            value={email}
                            sx={{
                                marginTop: '1.5em',
                            }}
                            onChange={(e) => handleChange('email', e.target.value)}
                        />
                        <TextField
                            required
                            type="password"
                            label="Previous Password"
                            disabled={!changePass}
                            value={previousPassword}
                            sx={{
                                marginTop: '1.5em', 
                            }}
                            onChange={(e) => handleChange('previousPassword', e.target.value)}
                        />
                        <TextField
                            required
                            type="password"
                            label="New Password"
                            disabled={!changePass}
                            value={newPassword}
                            sx={{
                                marginTop: '1.5em',
                            }}
                            onChange={(e) => handleChange('newPassword', e.target.value)}
                        />
                        <TextField
                            required
                            type="password"
                            label="Repeat New Password"
                            disabled={!changePass}
                            value={repeatPassword}
                            sx={{
                                marginTop: '1.5em', 
                                marginBottom: '1em',
                            }}
                            onChange={(e) => handleChange('repeatPassword', e.target.value)}
                        />
                        <FormGroup>
                            <FormControlLabel 
                            control={<Checkbox />} 
                            label="Change Password"
                            onChange={() => setChangePass(!changePass)}
                            />
                        </FormGroup>
                        {
                            changePass &&
                            !passwordsEqual &&
                            newPassword !== '' &&
                            repeatPassword !== '' &&
                            <p style={{
                                width: '100%',
                                textAlign: 'center',
                                color: 'red'
                            }}>
                                Passwords must match.
                            </p>
                        }

                        <div className="form-buttons-wrapper"> 
                            <Link to={'#'}>
                                <AcceptRejectModal
                                    openLabel='Update Settings'
                                    openDisabled={
                                        (changePass && (!passwordsEqual || previousPassword === ''))
                                        || ((email === username) && !changePass)
                                    }
                                    acceptLabel='Yes'
                                    acceptColor='red'
                                    rejectLabel='No'
                                    message={`
                                    Are you sure you want to update this information?
                                    You will be required to log back in after updating your email/password.
                                    `}
                                    callBack={handleSubmit}
                                />
                            </Link>
                        </div>
                </div>
            </div>
    )
}