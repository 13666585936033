import {
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    TextField,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { RolesSelection } from "../../../components/ComboBox/RolesSelection";
import { AcceptModal } from "../../../components/Modals/AcceptModal";
import { AcceptRejectModal } from "../../../components/Modals/AcceptRejectModal";
import { Participant } from "../../../interfaces/Participant";
import {
    useParticipants,
    useUserRoles,
    useUsers,
} from "../../../redux/hooks";
import {
    adminChangeUserPassword,
    getUserEditDetails,
    updateParticipantUsers,
    updateUser,
} from "../../../redux/users/usersHelpers";
import {
    emailWarning,
    passwordWarning,
    requiredFieldsWarning,
    validateEmail,
    validateIsEmployeeEmail,
    validatePassword,
} from "../../../validation/Validate";
import {
    getParticipantWearablesByUserId,
} from "../../../redux/participants/participantsHelpers";
import SelfAdvocateSelection from "../../../components/ComboBox/SelfAdvocateSelection";
import { CareTeamSelection } from "../../../components/ComboBox/CareTeamSelection";
import { MultiSelectParticipantsEdit } from "../../../components/MultiSelect/MultiSelect";

interface Params {
    id: string;
}

export const EditUser: React.FC = () => {
    // router
    const history = useHistory();

    // user data
    let { id } = useParams<Params>();
    const { allUsers, userEditDetails } = useUsers();
    const editUser = allUsers.find((user) => user.id === id);
    const [selfParticipant, setSelfParticipant] = useState<
        string | undefined
    >();

    // form state
    const [alias, setAlias] = useState<string>("");
    const [email, setEmail] = useState("");
    const [careTeamId, setCareTeamId] = useState<string | undefined>(undefined);
    const [password, setPassword] = useState("");
    const [roleId, setRoleId] = useState("");
    const [changePass, setChangePass] = useState(false);
    const [ roleName, setRoleName ] = useState<string | undefined>(undefined);
    const { roles } = useUserRoles();

    // user feedback
    const [warningOpen, setWarningOpen] = useState(false);
    const [warningMessage, setWarningMessage] = useState("");

    // isEmployeeEmail = true adds role Admin to the dropdown
    const [isEmployeeEmail, setIsEmployeeEmail] = useState(
        editUser ? validateIsEmployeeEmail(editUser.email) : false
    );
    const {data: allParticipants} = useParticipants().allParticipants;
    const [participantSelection, setParticipantSelection] = useState<
        Participant[]
    >([]);

    // isSelfAdvocate boolean used for conditional rendering
    const isSelfAdvocate = useCallback(() => {
        const role = roles.data.find((role) => role.id === roleId);
        return role?.roleName === "ROLE_SELF_ADVOCATE";
    },[roleName])

    // Always fetch user details on page load
    useEffect(() => {
        getUserEditDetails(id);
    }, []);

    // Always set role name on page load
    useEffect(() => {
        if (userEditDetails) {
            setRoleName(userEditDetails.role_name);
        }
    }, [userEditDetails]);

    // hydrate form with user details
    useEffect(() => {
        if (userEditDetails) {
            setAlias(userEditDetails.alias);
            setSelfParticipant(userEditDetails.self_participant);
            setEmail(editUser?.email || "");
            setRoleId(userEditDetails.role_id);
            setCareTeamId(userEditDetails.careTeamId);
        } else {
            getUserEditDetails(id);
        }
    }, [userEditDetails, editUser]);

    const handleChange = (field: string, value: string) => {
        switch (field) {
            case "alias":
                setAlias(value);
                return;
            case "role":
                setRoleName(roles.data.find(role => role.id === value)?.roleName);
                setRoleId(value);
                return;
            case "selfParticipant":
                setSelfParticipant(value);
                return;
            case "email":
                setEmail(value);
                setIsEmployeeEmail(validateIsEmployeeEmail(value));
                return;
            case "password":
                setPassword(value);
                return;
        }
    };

    async function handleSubmit() {
        if (!email) {
            setWarningMessage(requiredFieldsWarning);
            setWarningOpen(true);
            return;
        }
        if (!validateEmail(email)) {
            setWarningMessage(emailWarning);
            setWarningOpen(true);
            return;
        }
        if (changePass && !validatePassword(password)) {
            setWarningMessage(passwordWarning);
            setWarningOpen(true);
            return;
        }
        const participantIds = participantSelection.reduce(
            (acc: string[], curr) => [...acc, curr.id],
            []
        );
        // update user data in db
        await updateUser({
            id: id,
            participantIds: isSelfAdvocate() && selfParticipant ? [selfParticipant] : [...participantIds],
            email: email,
            alias: alias,
            roleId: roleId,
            careTeamId: careTeamId,
            selfParticipant: selfParticipant || undefined,
        }).then(async () => {
            // Hydrate participant_info data from materialized view for updated participant wearable information
            // Filter hydrated participant data by participant selection
            await getParticipantWearablesByUserId(id).then(async () => {
                // Update mongodb
                await updateParticipantUsers(id);
            });
        });
        if (changePass && password.length > 0) {
            adminChangeUserPassword({
                userId: id,
                newPassword: password,
            });
        }
        history.push("/admin/users");
    }

    if (!editUser || !userEditDetails) return <></>;
    return (
        <div className="form-page-wrapper">
            <AcceptModal
                message={warningMessage}
                isOpen={warningOpen}
                callBack={() => setWarningOpen(false)}
            />
            <div className="form-wrapper">
                <h2 className="form-header">Edit User</h2>
                <p className="form-description">
                    Fill out the fields below and click 'Edit', or click
                    'Cancel' to go back.
                </p>
                <TextField
                    type="text"
                    label="Alias"
                    value={alias}
                    sx={{ marginTop: "1.5em" }}
                    defaultValue={userEditDetails?.alias || ""}
                    onChange={(e) => handleChange("alias", e.target.value)}
                />
                <TextField
                    required
                    type="email"
                    label="Email"
                    value={email}
                    sx={{
                        marginTop: "1.5em",
                    }}
                    onChange={(e) => handleChange("email", e.target.value)}
                />
                <div style={{ marginTop: "1.5em" }} />
                <CareTeamSelection
                    callback={(id: string) => setCareTeamId(id)}
                    defaultId={editUser?.careTeamId}
                />
                <div style={{ marginTop: "1.5em" }} />
                <RolesSelection
                    callback={(id: string) => handleChange("role", id)}
                    defaultId={roleId}
                    isEmployeeEmail={isEmployeeEmail}
                />
                <div style={{ height: "1.5em" }} />
                {!isSelfAdvocate() ? (
                    <>
                        <MultiSelectParticipantsEdit
                            callback={(data: any) => setParticipantSelection(data)}
                            id={id}
                        />
                        <div style={{ height: "1.5em"}}/>     
                    </>
                ): null}
                

                {isSelfAdvocate() ? (
                    <p>Which participant is the self advocate?</p>
                ) : (
                    <p>
                        Is the user also a device wearer? If so, which
                        participant are they?
                    </p>
                )}
                <>
                    <div style={{ height: "1.5em" }} />
                    <SelfAdvocateSelection
                        setSelfParticipant={setSelfParticipant}
                        participantSelection={isSelfAdvocate() ? allParticipants : participantSelection}
                        defaultId={selfParticipant || null}
                    />
                </>
                <TextField
                    required
                    type="password"
                    label="Password"
                    disabled={!changePass}
                    value={password}
                    sx={{
                        marginTop: "1.5em",
                        marginBottom: "1em",
                    }}
                    onChange={(e) => handleChange("password", e.target.value)}
                />
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox />}
                        label="Change Password"
                        onChange={() => setChangePass(!changePass)}
                    />
                </FormGroup>

                <div className="form-buttons-wrapper">
                    <Link to={"#"} onClick={() => history.goBack()}>
                        <Button variant="contained">Cancel</Button>
                    </Link>
                    <Link to={"#"}>
                        <AcceptRejectModal
                            openLabel="Edit"
                            openDisabled={false}
                            acceptLabel="Yes"
                            acceptColor="red"
                            rejectLabel="No"
                            message="Are you sure you want to edit this record?"
                            callBack={handleSubmit}
                        />
                    </Link>
                </div>
            </div>
        </div>
    );
};
