import { DataGrid, GridSortModel } from "@mui/x-data-grid";
import React, { useState } from "react";
import {
    AnalyticsTag,
    AnalyticsEventName,
    NotificationTableParams,
} from "../../analytics";
import { CreateAnalyticsEvent } from "../../redux/analytics/analyticsHelpers";
import { useNotifications } from "../../redux/hooks";
import "./NotificationsTable.css";

interface Props {
    selectCallBack: (selection: any) => void;
}

export enum TableType {
    careTeam = "careteam",
    organization = "organization",
    participant = "participant",
}

enum Month {
    "Jan" = 0,
    "Feb" = 1,
    "Mar" = 2,
    "Apr" = 3,
    "May" = 4,
    "Jun" = 5,
    "Jul" = 6,
    "Aug" = 7,
    "Sep" = 8,
    "Oct" = 9,
    "Nov" = 10,
    "Dec" = 11,
}

export const NotificationsTable: React.FC<Props> = (Props: Props) => {
    const [pageSize, setPageSize] = useState<number>(25);
    const { data, pending } = useNotifications();
    // Analytics
    const clickEvent = CreateAnalyticsEvent<NotificationTableParams>(
        AnalyticsEventName.NOTIFICATIONS,
        AnalyticsTag.CLICK
    );

    if(pending && data === undefined) {
        return <></>
    }

    // If no notes, display message
    if (data.hasOwnProperty("length") && data.length === 0 && !pending) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: 150,
                }}
            >
                <p
                    style={{
                        fontSize: 18,
                        fontWeight: 400,
                    }}
                >
                    No notes recorded.
                </p>
            </div>
        );
    }

    function handleClickEvent(
        label: string,
        props: number | GridSortModel | NotificationTableParams
    ) {
        switch (label) {
            case "page_change":
                clickEvent.Save({
                    params: { page: "dashboard", button: label },
                });
                break;
            case "page_size_change":
                clickEvent.Save({
                    params: {
                        page: "dashboard",
                        button: label,
                        page_size: props as number,
                    },
                });
                break;
            case "sort_model_change":
                clickEvent.Save({
                    params: {
                        action: label,
                        ...(props as NotificationTableParams),
                    },
                });
                break;
            default:
                break;
        }
    }

    const getEmoji = (emotion: string) => {
        switch (emotion) {
            case "happy":
                return "😃   Happy";
            case "anxious":
                return "😰   Anxious";
            case "neutral":
                return "😐   Neutral";
            case "not_sure":
                return "？  Not Sure";
            default:
                return "--";
        }
    };

    function stringDate(timestamp: number): string {
        const date = new Date(timestamp);
        const month = date.getMonth();
        const day = date.getDate();
        return `${Month[month]}. ${day}`;
    }

    const data_mapped = data.map((data) => {
        const date = new Date(data.time);
        const minutes = date.getMinutes().toString();
        const minutes_readable = minutes.length === 1 ? "0" + minutes : minutes;
        const time_string = `${date.getHours()}:${minutes_readable}`;

        return {
            id: data._id,
            beforeNotification: data.answers.beforeNotification
                ? data.answers.beforeNotification
                : "--",
            afterNotification: data.answers.afterNotification
                ? data.answers.afterNotification
                : "--",
            emotion: data.answers.emotion
                ? getEmoji(data.answers.emotion)
                : "--",
            anxietyState: data.anxietyState,
            time: time_string,
            date: stringDate(data.time),
        };
    });

    var columns = [
        { field: "date", headerName: "Date", width: 100 },
        { field: "time", headerName: "Time", width: 75 },
        { field: "anxietyState", headerName: "Level of Emotion", width: 150 },
        { field: "emotion", headerName: "Emotion", width: 100 },
        {
            field: "beforeNotification",
            headerName: "What happened before the notification",
            width: 650,
        },
        {
            field: "afterNotification",
            headerName: "How did you respond to the notification",
            width: 650,
        },
    ];

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                // width: "inherit",
                height: data.length < 25 ? data.length * 60 + 175 : 1675,
                flex: 1,
                marginTop: "2em",
            }}
        >
            <p style={{ marginBottom: "0.5em", fontSize: 24 }}>Notifications</p>
            <DataGrid
                columns={columns}
                rows={data_mapped}
                onSelectionModelChange={(newSelectionModel) => {
                    Props.selectCallBack(newSelectionModel[0]);
                }}
                // Analytics
                onPageChange={(props) => handleClickEvent("page_change", props)}
                onSortModelChange={(props) =>
                    handleClickEvent("sort_model_change", props)
                }
                onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                    handleClickEvent("page_size_change", newPageSize);
                }}
                // Other props
                getRowHeight={(params) => 60}
                disableSelectionOnClick
                rowsPerPageOptions={[25, 50, 100]}
                pageSize={pageSize}
            />
        </div>
    );
};
