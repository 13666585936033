import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { DB_ENDPOINT } from '../db'
import { encodeRequestBody } from '../helpers'
import { store } from '../store'
import { DefaultState, PostArgs, PutArgs } from './interfaces'

const ROUTE = '/user-management'
const PATH = DB_ENDPOINT + ROUTE

const getHeaders = () => {
    if(!store.getState().tokens.accessToken) return;

    var token = store.getState().tokens.accessToken
    var headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
    }
    return headers
}

export const fetchSites = createAsyncThunk(
    'sites/fetchSites',
    async() => {
        return fetch(PATH + `/site/`,
        { 
            method: 'GET', 
            headers: getHeaders(),
        }  
        )
        .then(res => res.json())
    }
)

export const fetchSitesByOrganizationId = createAsyncThunk(
    'sites/fetchSitesByOrganizationId',
    async(id: string) => {
        return fetch(PATH + `/site/organization/${id}`,
        { 
            method: 'GET', 
            headers: getHeaders(),
        }  
        )
        .then(res => res.json())
    }
)

export const putSite = createAsyncThunk(
    'sites/putSites',
    async(args: PutArgs) => {
        let requestBody = encodeRequestBody(args)
        return fetch(PATH + `/site/${args.id}`,
            { 
                method: 'PUT', 
                headers: getHeaders(),
                body: requestBody
            }  
        )
        .then(res => res.json())
        .catch(res => res.json())
    }
)

export const postSite = createAsyncThunk(
    'sites/postSites',
    async(args: PostArgs) => {
        let requestBody = encodeRequestBody(args)
        return fetch(PATH + '/site',
            {
                method: 'POST',
                headers: getHeaders(),
                body: requestBody
            }
        )
        .then(res => res.json())
        .catch(res => res.json())
    }
)

export const removeSite = createAsyncThunk(
    'sites/removeSite',
    async(id: string) => {
        return fetch(PATH + `/site/remove/${id}`,
            {
                method: 'DELETE',
                headers: getHeaders(),
            }
        )
        .then(res => res.json())
        .catch(res => res.json())
    }
)

const initialState: DefaultState = {
    allSites: {
        pending: false,
        data: []
    },
}

const sitesSlice = createSlice({
    name: 'sites',
    initialState,
    reducers: {
        resetSitesState(state) {
            state.allSites.data = []
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSites.pending, (state) => {
            state.allSites.pending = true
        })
        builder.addCase(fetchSites.fulfilled, (state, action) => {
            state.allSites.data = action.payload
            state.allSites.pending = false
        })
        builder.addCase(fetchSitesByOrganizationId.pending, (state) => {
            state.allSites.pending = true
        })
        builder.addCase(fetchSitesByOrganizationId.fulfilled, (state, action) => {
            state.allSites.data = action.payload
            state.allSites.pending = false
        })
        builder.addCase(putSite.pending, (state) => {
            state.allSites.pending = true
        })
        builder.addCase(putSite.fulfilled, (state, action) => {
            // Add updated object to state optimistically
            state.allSites.pending = false
        })
        builder.addCase(postSite.pending, (state) => {
            state.allSites.pending = true
        })
        builder.addCase(postSite.fulfilled, (state, action) => {
            // Add updated object to state optimistically
            state.allSites.pending = false
        })
        builder.addCase(removeSite.pending, (state) => {
            state.allSites.pending = true
        })
        builder.addCase(removeSite.fulfilled, (state, action) => {
            // Update state optimistically
            state.allSites.pending = false
        })
    }
})

export const { resetSitesState } = sitesSlice.actions

export default sitesSlice.reducer