import { store } from "../store";
import { validateTokenExpiry } from "../tokens/tokensHelpers";
import { UserRolePostArgs } from "./interfaces";
import { fetchRoles, fetchUserRolesByUserId, postUserRole } from "./userRolesSlice";

export async function getRoles() {
    await validateTokenExpiry()
    await store.dispatch(fetchRoles());
};

export async function getUserRolesByUserId(userId: string) {
    await validateTokenExpiry()
    await store.dispatch(fetchUserRolesByUserId(userId));
};

export async function createUserRole(args: UserRolePostArgs) {
    await validateTokenExpiry()
    await store.dispatch(postUserRole(args));
};