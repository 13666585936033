import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { INSIGHTS_ENDPOINT, REPORT_DB_ENDPOINT } from '../db'
import { DefaultState, FetchArgs } from './interfaces'
import { store } from '../store'

const ROUTE = '/report-management'
const PATH = REPORT_DB_ENDPOINT + ROUTE

const getHeaders = () => {
    if(!store.getState().tokens.accessToken) return;

    var token = store.getState().tokens.accessToken
    var headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
    } 
    return headers;
}

export const fetchReportById = createAsyncThunk(
    'reports/fetchReportById',
    async(args: FetchArgs) => {
        const { reportId } = args
        return fetch(PATH + `/query/id?reportId=${reportId}`,
        { 
            method: 'GET', 
            headers: getHeaders(),
        }  
        )
        .then(res => res.json())
    }
)

export const fetchOrganizationalBetaReports = createAsyncThunk(
    'reports/fetchOrganizationalBetaReports',
    async(organizationId: string) => {
        return fetch(`${INSIGHTS_ENDPOINT}/reports/active-users?organizationId=${organizationId}&utcOffset=-4`,
        { 
            method: 'GET', 
            headers: getHeaders(),
        }  
        )
        .then(res => res.json())
    }
)

export const fetchParticipantBetaReports = createAsyncThunk(
    'reports/fetchParticipantBetaReports',
    async(organizationId: string) => {
        return fetch(`${INSIGHTS_ENDPOINT}/reports/active-participants?organizationId=${organizationId}&utcOffset=-4`,
        { 
            method: 'GET', 
            headers: getHeaders(),
        }  
        )
        .then(res => res.json())
    }
)

export const fetchCaregiverBetaReports = createAsyncThunk(
    'reports/fetchCaregiverBetaReports',
    async(organizationId: string) => {
        return fetch(`${INSIGHTS_ENDPOINT}/reports/active-caregivers?organizationId=${organizationId}`,
        { 
            method: 'GET', 
            headers: getHeaders(),
        }  
        )
        .then(res => res.json())
    }
)

export const fetchTeamBetaReports = createAsyncThunk(
    'reports/fetchTeamBetaReports',
    async(organizationId: string) => {
        return fetch(`${INSIGHTS_ENDPOINT}/reports/active-teams?organizationId=${organizationId}`,
        { 
            method: 'GET', 
            headers: getHeaders(),
        }  
        )
        .then(res => res.json())
    }
)

export const fetchLatestReport = createAsyncThunk(
    'reports/fetchLatestReport',
    async(args: FetchArgs) => {
        const { participantId } = args
        return fetch(PATH + `/query/last?participantId=${participantId}`,
        { 
            method: 'GET', 
            headers: getHeaders(),
        }  
        )
        .then(res => res.json())
    }
)

export const fetchReportsByRange = createAsyncThunk(
    'reports/fetchReportsByRange',
    async(args: FetchArgs) => {
        const { participantId, startAt, endAt } = args
        return fetch(PATH + `/query/range?participantId=${participantId}&startAt=${startAt}&endAt=${endAt}`,
        { 
            method: 'GET', 
            headers: getHeaders(),
        }  
        )
        .then(res => res.json())
    }
)

export const fetchAnxietyCounts = createAsyncThunk(
    'reports/fetchAnxietyCounts',
    async(args: FetchArgs) => {
        const { participantId, startAt, endAt } = args
        return fetch(PATH + `/query/states?participantId=${participantId}&startAt=${startAt}&endAt=${endAt}`,
        { 
            method: 'GET', 
            headers: getHeaders(),
        }  
        )
        .then(res => res.json())
    }
)

const initialState: DefaultState = {
    reportById: {
        pending: false,
        data: []
    },
    latestReport: {
        pending: false,
        data: []
    },
    reportsByRange: {
        pending: false,
        data: [],
        selectedRange: null,
        startAt: null,
        endAt: null
    },
    anxietyCounts: {
        pending: false,
        data: []
    },
    betaReportOrganization: {
        pending: false,
        data: []
    },
    betaReportParticipant: {
        pending: false,
        data: []
    },
    betaReportCareGiver: {
        pending: false,
        data: []
    },
    betaReportTeams: {
        pending: false,
        data: []
    }
}

const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        resetReportsState(state) {
            state.anxietyCounts.data = [];
            state.latestReport.data = [];
            state.reportById.data = [];
            state.reportsByRange.data = [];
        },
        setReportStartEnd(state, action) {
            state.reportsByRange.startAt = action.payload.startAt;
            state.reportsByRange.endAt = action.payload.endAt;
        },
        setReportTimeRange(state, action) {
            state.reportsByRange.selectedRange = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchOrganizationalBetaReports.pending, (state) => {
            state.betaReportOrganization.pending = true
        })
        builder.addCase(fetchOrganizationalBetaReports.fulfilled, (state, action) => {
            state.betaReportOrganization.data[0] = action.payload
            state.betaReportOrganization.pending = false
        })
        builder.addCase(fetchParticipantBetaReports.pending, (state) => {
            state.betaReportParticipant.pending = true
        })
        builder.addCase(fetchParticipantBetaReports.fulfilled, (state, action) => {
            state.betaReportParticipant.data = action.payload
            state.betaReportParticipant.pending = false
        })
        builder.addCase(fetchCaregiverBetaReports.pending, (state) => {
            state.betaReportCareGiver.pending = true
        })
        builder.addCase(fetchCaregiverBetaReports.fulfilled, (state, action) => {
            state.betaReportCareGiver.data = action.payload
            state.betaReportCareGiver.pending = false
        })
        builder.addCase(fetchTeamBetaReports.pending, (state) => {
            state.betaReportTeams.pending = true
        })
        builder.addCase(fetchTeamBetaReports.fulfilled, (state, action) => {
            state.betaReportTeams.data = action.payload
            state.betaReportTeams.pending = false
        })
        builder.addCase(fetchReportById.pending, (state) => {
            state.reportById.pending = true
        })
        builder.addCase(fetchReportById.fulfilled, (state, action) => {
            state.reportById.data = action.payload
            state.reportById.pending = false
        })
        builder.addCase(fetchLatestReport.pending, (state) => {
            state.latestReport.pending = true
        })
        builder.addCase(fetchLatestReport.fulfilled, (state, action) => {
            state.latestReport.data = action.payload
            state.latestReport.pending = false
        })
        builder.addCase(fetchReportsByRange.pending, (state) => {
            state.reportsByRange.pending = true
        })
        builder.addCase(fetchReportsByRange.fulfilled, (state, action) => {
            state.reportsByRange.data = action.payload
            state.reportsByRange.pending = false
        })
        builder.addCase(fetchAnxietyCounts.pending, (state) => {
            state.anxietyCounts.pending = true
        })
        builder.addCase(fetchAnxietyCounts.fulfilled, (state, action) => {
            state.anxietyCounts.data = action.payload
            state.anxietyCounts.pending = false
        })
    }
})

export const { resetReportsState, setReportTimeRange, setReportStartEnd } = reportsSlice.actions

export default reportsSlice.reducer