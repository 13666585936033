import { useChartTypes, useParticipants, useUser} from "../../redux/hooks";
import { getParticipantsByUserId, selectParticipant } from "../../redux/participants/participantsHelpers";
import { ChartTabs } from "../../components/Charts/ChartTabs/ChartTabs";
import { ComboBox, Options } from "../../components/ComboBox/ComboBox";
import { Participant } from "../../interfaces/Participant";
import { useEffect, useState } from "react";
import { DateButtonsTimeSeries } from "../../components/Dates/DateButtonsTimeSeries";
import { DateButtonsHeatMap } from "../../components/Dates/DateButtonsHeatMap";
import { ChartTypes } from "../../redux/components/interfaces";
import { AnalyticsTag, AnalyticsEventName, ClickParams, SelectionParams} from "../../analytics";
import { CreateAnalyticsEvent } from "../../redux/analytics/analyticsHelpers";
import { ToolTipLegend } from "../../components/Charts/HeatMap/HeatMap";
import { Loading } from "../../components/Loading/Loading";

export const Dashboard: React.FC = () => {
    const { allParticipants, selectedParticipant } = useParticipants();
    const [ dropDownOptions, setDropDownOptions ] = useState([{label: "None", value: "none"}]);
    const {selected} = useChartTypes();
    const {user_id} = useUser();
    // Analytics
    const clickEvent = CreateAnalyticsEvent<ClickParams & SelectionParams>(AnalyticsEventName.DASHBOARD_GRAPHS, AnalyticsTag.CLICK)

    useEffect(() => {
        if(user_id)
        getParticipantsByUserId(user_id);
    },[user_id]);

    
    const selectDropDown = (selection: Options | null) => {
        if(selection?.value) {
            selectParticipant(selection.value, true)
            clickEvent.Save({params: {
                page: "dashboard",
                button: "select_participant", 
                key: "participantId",
                value: selection.value
            }})
        }   
    }
    
    // Create options for drop down list
    useEffect(() => {
        if(allParticipants.data && allParticipants.data.length > 0) {
            let options = allParticipants.data.reduce((acc: any[], participant: Participant) => {
                acc.push({
                    label: participant.description,
                    value: participant.id
                })
                return acc
            },[])
            setDropDownOptions(options)
        }
    },[allParticipants])
    
    return (
        <>
        <p style={{marginBottom: '0.5em', fontSize: 24}}>Participants</p>
        <p style={{marginBottom: '1.5em'}}>Select one of the Participants</p>
        {
        allParticipants.data?.hasOwnProperty('length') && allParticipants.data.length > 0 &&
        <ComboBox label={"Participants"} callback={selectDropDown} options={dropDownOptions}/>
        }
       
        <div>
        {
            selected === ChartTypes[1] && selectedParticipant.data && <ToolTipLegend />
        }
        <div
            style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                flexWrap: 'wrap'
            }}
            >
                {
                    selected === ChartTypes[0] && <DateButtonsTimeSeries />    
                }
                {
                    selected === ChartTypes[1] && <DateButtonsHeatMap />
                }
            </div>
            <ChartTabs />
        </div>
        </>
    )
}