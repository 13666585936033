import jwtDecode from 'jwt-decode'
import { logOut } from '../helpers';
import { store } from '../store'
import { FetchArgs, FetchArgsRefreshToken, LocalStorage, RefreshTokenDecoded, TokenDecoded } from './interfaces';
import { fetchTokens, refreshTokens } from './tokensSlice'
interface AuthenticationErrors {
    error: string,
    error_description: string
}

export interface AuthenticationResponse {
    authenticated: boolean,
    message: string | null
}

export async function getToken(email: string, password: string): Promise<AuthenticationResponse> {
    const args: FetchArgs = { email, password };
    let authenticated: AuthenticationResponse
    
    authenticated = await store.dispatch(fetchTokens(args)).then(
        //TODO: User correct interface here
        (response: any) => {
            if(response.payload.error) {
                return {
                    authenticated: false,
                    message: 'Invalid username or password'
                };
            }

            if(store.getState().tokens.accessToken) {
                return {
                    authenticated: true, 
                    message: null
                }
            }
            
            return {
                authenticated: false,
                message: 'Server Error'
            }
    }).catch(err => {
        return {
            authenticated: false,
            message: 'Unknown error'
        };
    })

    return authenticated
}

export function decodeToken(token: string): TokenDecoded {
    var token_data: TokenDecoded = jwtDecode(token);
    return token_data;
}

export function decodeRefreshToken(refreshToken: string): RefreshTokenDecoded {
    var refresh_token_data: RefreshTokenDecoded = jwtDecode(refreshToken);
    return refresh_token_data;
}

export function checkTokensLocalStorage(): boolean {
        var tokens: LocalStorage = {
            accessToken: localStorage.getItem('accessToken'),
            refreshToken: localStorage.getItem('refreshToken')
        };

        if(tokens.accessToken && tokens.refreshToken) {
            return true;
        }

        return false;
}


export function addTokensToLocalStorage(tokens: LocalStorage) {
    if(tokens.accessToken && tokens.refreshToken) {
        localStorage.setItem('accessToken', tokens.accessToken);
        localStorage.setItem('refreshToken', tokens.refreshToken);
    };
}

export function removeTokensLocalStorage() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
}

export async function validateTokenExpiry() {
    const { tokens } = store.getState();
    if(!tokens.tokenData.exp ||!tokens.tokenData.refreshExp || !tokens.refreshToken) {
        logOut();
        return;
    }

    var timestamp_now = Math.round(new Date().getTime() / 1000);
    if(tokens.tokenData.refreshExp < timestamp_now) {
        // Refresh token is expired, log out
        logOut();
        return;
    }
    if(tokens.tokenData.exp < timestamp_now) {
        // Access token is expired, fetch new token
        let args: FetchArgsRefreshToken = { refreshToken: tokens.refreshToken};
        await store.dispatch(refreshTokens(args))
           //Token is refreshed
    }
    // Access token is valid
};

export async function getNewToken() {
    const { tokens } = store.getState();

    if(tokens.refreshToken) {
        let args: FetchArgsRefreshToken = { refreshToken: tokens.refreshToken};
            await store.dispatch(refreshTokens(args))
    }
}