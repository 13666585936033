import { Button } from "@mui/material"
import { useEffect, useState } from "react"
import Report from "../../interfaces/Report"
import { useReports } from "../../redux/hooks"
import { BasicModal } from "./BasicModal"

interface Props {
    callBack?: Function,
    isOpen: boolean
}

enum months {
    "Jan" = 0,
    "Feb" = 1,
    "Mar" = 2,
    "Apr" = 3,
    "May" = 4,
    "Jun" = 5,
    "Jul" = 6,
    "Aug" = 7,
    "Sept" = 8,
    "Oct" = 9,
    "Nov" = 10,
    "Dec" = 11
}

enum days {
    "Sun" = 0,
    "Mon" = 1,
    "Tue" = 2,
    "Wed" = 3,
    "Thu" = 4,
    "Fri" = 5,
    "Sat" = 6
}

const parseDate = (date: number) => {
    let timestamp = new Date(date)
    let y = timestamp.getFullYear()
    let m = months[timestamp.getMonth()]
    let d = timestamp.getDate()
    let day = days[timestamp.getDay()]
    let h = timestamp.getHours()
    let min = timestamp.getMinutes()
    let formatted = `${day} ${d} ${m} ${y} ${h}:${min}`
    return formatted
}

export const WearableConnectionModal: React.FC<Props> = ({isOpen, callBack}) => {

    const [open, setOpen] = useState(false)
    const { latestReport } = useReports();
    const [time, setTime] = useState<any>(null);

    useEffect(() => {
        let data = latestReport.data as unknown
        let report: Report = data as Report
        if (report.time) {
            
            setTime(parseDate(report.time))
        } else {
            setTime(null)
        }
    },[latestReport])

    useEffect(() => {
        setOpen(isOpen)
    }, [isOpen])

    const handleAccept = () => {
        setOpen(false)
        callBack && callBack()
    }

    const modalContent = 
        <div style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            flexDirection: 'column',
        }}>
                {
                    time &&
                    <>
                        <p>
                        Last watch activity received at: <br />{time}
                        </p>
                        <br />
                    </>
                }
                {
                    !time && !latestReport.pending &&
                    <>
                        <p>
                        No watch activity received yet.
                        </p>
                        <br />
                    </>
                }
                {
                    latestReport.pending && 
                    <>
                    <p>
                    Checking Connection...
                    </p>
                    <br />
                </>
                }
            <div>
                <Button 
                    variant="contained" 
                    onClick={handleAccept}
                >
                    OK
                </Button>
            </div>
        </div>
 
    return (
        <>
            <BasicModal
            open={open}
            callback={callBack}
            >
                <p style={{textAlign: 'center'}}>
                    {modalContent}
                </p>
            </BasicModal>
        </>
    )
}