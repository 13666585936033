import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AUTH_ENDPOINT } from '../db'
import { DefaultState, FetchArgs, FetchArgsRefreshToken, LocalStorage, RefreshTokenDecoded, TokenDecoded, TokenPayload } from './interfaces'
import { addTokensToLocalStorage, decodeRefreshToken, decodeToken } from './tokensHelpers'

const PATH = AUTH_ENDPOINT

let headers = {  
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
}

const setRequestBodyAccessToken = (username: string, password: string) => {
    const body: any = {
        scope: 'any',
        grant_type: 'password',
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        username: username,
        password: password
    }
    
    var formBody = [];
    for (var property in body) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(body[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    var requestBody = formBody.join("&");
    return requestBody
}

export const fetchTokens = createAsyncThunk(
    'tokens/fetchToken',
    async(args: FetchArgs) => {
        const requestBody = setRequestBodyAccessToken(args.email, args.password)
        return fetch(PATH,
        { 
            method: 'POST', 
            headers: headers,
            body: requestBody
        })
        .then(res => {
            return res.json();
        })
    }
)

const setRequestBodyRefreshToken = (refreshToken: string) => {
    const body: any = {
        scope: 'any',
        grant_type: 'refresh_token',
        client_id: 'mobile',
        client_secret: '123',
        refresh_token: refreshToken
    }
    
    var formBody = [];
    for (var property in body) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(body[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    var requestBody = formBody.join("&");
    return requestBody
}

export const refreshTokens = createAsyncThunk(
    'tokens/refreshTokens',
    async(args: FetchArgsRefreshToken) => {
        const requestBody = setRequestBodyRefreshToken(args.refreshToken)
        return fetch(PATH,
        { 
            method: 'POST', 
            headers: headers,
            body: requestBody
        }    
        )
        .then(res => {
            return res.json()
        })
    }
)

const initialState: DefaultState = {
    pending: false,
    error: null,
    accessToken: null,
    refreshToken: null,
    tokenData: {
        username: null,
        user_id: null,
        organization_id: null,
        care_team_id: null,
        participant_id: null,
        scope: null,
        authorities: null,
        exp: null,
        refreshExp: null,
    }
}

const tokensSlice = createSlice({
    name: 'tokens',
    initialState,
    reducers: {
        storeTokensFromLocalStorage(state) {
            const accessToken = localStorage.getItem('accessToken');
            const refreshToken = localStorage.getItem('refreshToken');

            if(accessToken && refreshToken) {
                state.accessToken = accessToken;
                state.refreshToken = refreshToken;
                // Parse token data
                const tokenData: TokenDecoded = decodeToken(localStorage.accessToken);
                const refreshTokenData: RefreshTokenDecoded = decodeRefreshToken(localStorage.refreshToken)
                // Add token data to Redux context
                state.tokenData.username = tokenData.user_name;
                state.tokenData.user_id = tokenData.user_id[0];
                state.tokenData.scope = tokenData.scope;
                state.tokenData.authorities = tokenData.authorities;
                state.tokenData.organization_id = tokenData.organization_id;
                state.tokenData.care_team_id = tokenData.care_team_id;
                state.tokenData.participant_id = tokenData.participant_id;
                state.tokenData.exp = tokenData.exp;
                state.tokenData.refreshExp = refreshTokenData.exp;
            };
        },
        resetTokensState(state) {
            state.accessToken = null;
            state.refreshToken = null;
            state.tokenData = {
                username: null,
                user_id: null,
                organization_id: null,
                care_team_id: null,
                participant_id: null,
                scope: null,
                authorities: null,
                exp: null,
                refreshExp: null
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTokens.pending, (state) => {
            state.pending = true;
        })
        builder.addCase(fetchTokens.fulfilled, (state, action) => {
            if(action.payload.error) {
                state.pending = false;
                return;
            }
            const tokenPayload: TokenPayload = action.payload;
            // TODO: Status code handling 400
            state.accessToken = tokenPayload.access_token;
            state.refreshToken = tokenPayload.refresh_token;
            // Parse token data
            
            const tokenData: TokenDecoded = decodeToken(tokenPayload.access_token)
            const refreshTokenData: RefreshTokenDecoded = decodeRefreshToken(tokenPayload.refresh_token)
            // Add token data to Redux context
            state.tokenData.username = tokenData.user_name;
            state.tokenData.user_id = tokenData.user_id[0];
            state.tokenData.scope = tokenData.scope;
            state.tokenData.authorities = tokenData.authorities;
            state.tokenData.organization_id = tokenData.organization_id;
            state.tokenData.care_team_id = tokenData.care_team_id;
            state.tokenData.participant_id = tokenData.participant_id;
            state.tokenData.exp = tokenData.exp;
            state.tokenData.refreshExp = refreshTokenData.exp;
            // Store tokens in local storage
            const tokens: LocalStorage = {
                accessToken: tokenPayload.access_token,
                refreshToken: tokenPayload.refresh_token
            }
            addTokensToLocalStorage(tokens)
            state.pending = false;
        })
        builder.addCase(refreshTokens.pending, (state) => {
            state.pending = true;
        })
        builder.addCase(refreshTokens.fulfilled, (state, action) => {
            if(action.payload.error) {
                state.pending = false;
                return;
            }
            const tokenPayload: TokenPayload = action.payload;
            // TODO: Status code handling 400
            state.accessToken = tokenPayload.access_token;
            state.refreshToken = tokenPayload.refresh_token;
            // Parse token data
            
            const tokenData: TokenDecoded = decodeToken(tokenPayload.access_token);
            const refreshTokenData: RefreshTokenDecoded = decodeRefreshToken(tokenPayload.refresh_token)
            // Add token data to Redux context
            state.tokenData.username = tokenData.user_name;
            state.tokenData.scope = tokenData.scope;
            state.tokenData.authorities = tokenData.authorities;
            state.tokenData.organization_id = tokenData.organization_id;
            state.tokenData.care_team_id = tokenData.care_team_id;
            state.tokenData.participant_id = tokenData.participant_id;
            state.tokenData.exp = tokenData.exp;
            state.tokenData.refreshExp = refreshTokenData.exp;
            // Store tokens in local storage
            const tokens: LocalStorage = {
                accessToken: tokenPayload.access_token,
                refreshToken: tokenPayload.refresh_token
            }
            addTokensToLocalStorage(tokens)
            state.pending = false;
        })
    }
})

export const { storeTokensFromLocalStorage, resetTokensState } = tokensSlice.actions

export default tokensSlice.reducer