import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useEffect, useState } from 'react';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
  borderColor: 'transparent'
};

interface ModalProps {
    children: JSX.Element,
    open: boolean,
    callback?: Function
}

export const BasicModal: React.FC<ModalProps> = (Props: ModalProps) => {
    const { children, open, callback } = Props
    const [isOpen, setIsOpen] = useState(false);

    const handleClose = () => {
        setIsOpen(false);
        callback && callback()
    }

    useEffect(() => {
        setIsOpen(open)
    }, [open])

    return (
        <div>
            <Modal
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
                {children}
            </Box>
            </Modal>
        </div>
    );
}