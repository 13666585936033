import { store } from "../store";
import { validateTokenExpiry } from "../tokens/tokensHelpers";
import { getWindowDimensions } from "../../hooks/hooks";
import { 
    AnalyticsEvent, 
    AnalyticsEventDTO, 
    AnalyticsEventName, 
    AnalyticsPlatform, 
    AnalyticsTag 
} from "../../analytics";
import { 
    fetchEventsByOrganizationId, 
    filterEvents,  
    postAnalyticsEvent 
} from "./analyticsSlice";
import { VERSION } from "../db";

export async function getEventsByOrganizationId(organizationId: string, platform: string, userId: string, from: number, to: number) {
    await validateTokenExpiry()
    await store.dispatch(fetchEventsByOrganizationId({organizationId}))
    store.dispatch(filterEvents({platform, userId, from, to}))
}

export function sortEvents(platform: string, userId: string, from: number, to: number) {
    store.dispatch(filterEvents({platform, userId, from, to}))
} 

export async function saveAnalyticsEvent<Params>(e: AnalyticsEventDTO<Params, AnalyticsEventName>) {
    await validateTokenExpiry()
    await store.dispatch(postAnalyticsEvent(e))
}

export function CreateAnalyticsEvent<Params>(name: AnalyticsEventName, tag: AnalyticsTag) {
    return new AnalyticsEvent<Params, AnalyticsEventName>({
        event: {
            name: name,
            platform_version: VERSION,
            tag: tag,
            platform: AnalyticsPlatform.DASHBOARD,
            resolution: getWindowDimensions(),
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        },
        PostFunc: (e => saveAnalyticsEvent(e))
    })
}