import {isEmpty, isEqual} from "lodash";
import { useEffect, useState } from "react";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveIcon from '@mui/icons-material/Remove';
import './MultiSelect.css';
import { useAppSelector, useOrganizationId, useParticipants, useUsers } from "../../redux/hooks";
import { getParticipantsByOrganizationId } from "../../redux/participants/participantsHelpers";
import { filterUnassignedParticipants, getParticipantUsers } from "../../redux/users/usersHelpers";
import { Loading } from "../Loading/Loading";

export interface Selection {
    [key: string]: string;
};

interface MultiSelectProps {
    selection: Selection[];
    selectionKey: string;
    callback: (s: Selection) => void;
    icon: any;
};

const MultiSelect: React.FC<MultiSelectProps> = (
    {selection, selectionKey, callback, icon}
) => {

    const [_selection, setSelection] = useState<Selection[]>([]);

    useEffect(() => {
        setSelection(selection);
    },[selection]);

    return (
        <div className="multi-select-wrapper">
        {
            _selection.map((item, i) =>(
                <div className="multi-select-item" key={i}>
                    {
                        Object.keys(item)
                        .filter(key => key === selectionKey)
                        .map((ObjKey, i) => (
                            <div className="multi-select-item-name" key={i}>
                                {item[ObjKey]}
                            </div>
                        ))
                    }
                    <div 
                        className="multi-select-item-icon"
                        onClick={() => callback(item)}
                    >
                        {icon}
                    </div>
                </div>
            ))
        } 
        </div>
    );
};

interface MultiSelectParticipantsProps {
    id?: string,
    callback: (selected: Selection[]) => void;
};

const MultiSelectParticipantsCreate: React.FC<MultiSelectParticipantsProps> = (
    {callback}
) => {
    const organizationId = useOrganizationId();
    const participants: unknown[] = useParticipants().allParticipants.data;
    const [selected, setSelected] = useState<Selection[]>([]);
    const [notSelected, setNotSelected] = useState<Selection[]>([]);

    useEffect(() => {
        getParticipantsByOrganizationId(organizationId);
    },[organizationId])

    useEffect(() => {
        setNotSelected(participants as Selection[]);
        setSelected([]);
    },[participants])

    const addSelected = (item: Selection) => {
        if(!selected.includes(item)) {
            setNotSelected(notSelected.filter(s => !isEqual(s, item)));
            setSelected([item, ...selected]);
        };
    };

    const removeSelected = (item: Selection) => {
        if(!notSelected.includes(item)) {
            setSelected(selected.filter(s => !isEqual(s, item)));
            setNotSelected([item, ...notSelected]);
        }
    };

    useEffect(() => {
        callback(selected)
    },[callback, selected])

    return (
        <>
        {
            (!isEmpty(selected) || !isEmpty(notSelected)) &&
            <>
                <p style={{marginBottom: '1em'}}>Participants available for assignment:</p>
                <MultiSelect
                    callback={addSelected}
                    selection={notSelected}
                    selectionKey={'description'}
                    icon={<AddCircleOutlineIcon />}
                />
                <div style={{height: '1.5em'}} />
                <p style={{marginBottom: '1em'}}>Assigned participants:</p>
                <MultiSelect 
                    callback={removeSelected}
                    selection={selected}
                    selectionKey={'description'}
                    icon={<RemoveIcon />}
                />
            </>
        }
        </>
    )
};

const MultiSelectParticipantsEdit: React.FC<MultiSelectParticipantsProps> = (
    {id, callback}
) => {
    const organizationId = useOrganizationId();
    const participants = useParticipants().allParticipants.data;
    const participantUsers = useUsers().participantUsers
    const [selected, setSelected] = useState<Selection[]>([]);
    const [notSelected, setNotSelected] = useState<Selection[]>([]);
    const {pending} = useAppSelector(state => state.users);
    
    useEffect(() => {
        getParticipantsByOrganizationId(organizationId);
    },[organizationId]);

    useEffect(() => {
        if(id)
        getParticipantUsers(id);
    },[id]);

    useEffect(() => {
        if(participants.length !== 0) {
            let participantUsersConversion: unknown = participantUsers
            const unassignedParticipants: unknown = filterUnassignedParticipants(
                participantUsers, participants
            );
            setNotSelected(unassignedParticipants as Selection[]);
            setSelected(participantUsersConversion as Selection[]);
        }
    },[participants, participantUsers]);

    const addSelected = (item: Selection) => {
        if(!selected.includes(item)) {
            setNotSelected(notSelected.filter(s => !isEqual(s, item)));
            setSelected([item, ...selected]);
        };
    };

    const removeSelected = (item: Selection) => {
        if(!notSelected.includes(item)) {
            setSelected(selected.filter(s => !isEqual(s, item)));
            setNotSelected([item, ...notSelected]);
        };
    };

    useEffect(() => {
        callback(selected)
    },[callback, selected]);

    if(pending) {
        return <Loading message="Loading Participants"/>
    }

    return (
        <>
        {
            (!isEmpty(selected) || !isEmpty(notSelected)) &&
            <>
                <p style={{marginBottom: '1em'}}>Participants available for assignment:</p>
                <MultiSelect
                    callback={addSelected}
                    selection={notSelected}
                    selectionKey={'description'}
                    icon={<AddCircleOutlineIcon />}
                />
                <div style={{height: '1.5em'}} />
                <p style={{marginBottom: '1em'}}>Assigned participants:</p>
                <MultiSelect 
                    callback={removeSelected}
                    selection={selected}
                    selectionKey={'description'}
                    icon={<RemoveIcon />}
                />
            </>
        }
        </>
    );
};


export {
    MultiSelect,
    MultiSelectParticipantsCreate,
    MultiSelectParticipantsEdit
};