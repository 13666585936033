import { parseResForError } from "../errors/errorHelpers";
import { store } from "../store";
import { validateTokenExpiry } from "../tokens/tokensHelpers";
import { PostArgs, PutArgs } from "./interfaces";
import { fetchOrganizations, postOrganization, putOrganization, removeOrganization, selectOrganizationById } from "./organizationsSlice";

export async function getOrganizations() {
    await validateTokenExpiry();
    await store.dispatch(fetchOrganizations());
}

export async function selectOrganization(organizationId: string) {
    await validateTokenExpiry();
    if(organizationId)
    store.dispatch(selectOrganizationById(organizationId));
}

export async function updateOrganization(args: PutArgs) {
    await validateTokenExpiry();
    await store.dispatch(putOrganization(args))
    .then(res => parseResForError(res));
    return await store.dispatch(fetchOrganizations());
}

export async function createOrganization(args: PostArgs) {
    await validateTokenExpiry();
    await store.dispatch(postOrganization(args))
    .then(res => parseResForError(res));
    return await store.dispatch(fetchOrganizations());
}

export async function deleteOrganization(args: {id: string}) {
    await validateTokenExpiry();
    await store.dispatch(removeOrganization(args))
    .then(res => parseResForError(res, 405,
        `
        The organization could not be deleted,
        please ensure there are no site assigned to this organization.
        `
    ));

    return await store.dispatch(fetchOrganizations());
}