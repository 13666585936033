import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { ANALYTICS_ENDPOINT } from "../db";
import { DefaultState, FetchArgs } from "./interfaces";
import { getHeaders } from "../helpers";
import { store } from "../store";
import { AnalyticsEventDTO, AnalyticsEventName} from "../../analytics";

const PATH = ANALYTICS_ENDPOINT

export const postAnalyticsEvent = createAsyncThunk(
    'analytics/postAnalyticsEvent',
    async(e: AnalyticsEventDTO<any, AnalyticsEventName>) => {
        var token = store.getState().tokens.accessToken;
        var headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        } 
        return fetch(PATH + `/events/${e.tag}`,
            {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(e)
            }
            )
            .then(res => {
                return res.json()
            })
        }
)

export const fetchEventsByUserId = createAsyncThunk(
    'analytics/fetchEventsByUserId',
    async(args: FetchArgs) => {
        return fetch(PATH + `/events/user?userId=${args.userId}`,
        { 
            method: 'GET', 
            headers: getHeaders(),
        }
        )
        .then(res => res.json());
    }
)
export const fetchEventsByOrganizationId = createAsyncThunk(
    'analytics/fetchEventsByOrganizationId',
    async(args: FetchArgs) => {
        return fetch(PATH + `/events/organization?organizationId=${args.organizationId}`,
        { 
            method: 'GET', 
            headers: getHeaders(),
        }
        )
        .then(res => 
            res.json()
        );
    }
)

export const fetchEventsByUserIdSortedByName = createAsyncThunk(
    'analytics/fetchEventsByUserIdSortedByName',
    async(args: FetchArgs) => {
        return fetch(PATH + `/events/name/all?userId=${args.userId}`,
        { 
            method: 'GET', 
            headers: getHeaders(),
        }
        )
        .then(res => res.json());
    }
)

const initialState: DefaultState = {
    eventsCache: {
        pending: false,
        data: []
    },
    events: {
        pending: false,
        data: []
    },
    eventsByName: {
        pending: false,
        data: []
    }
}

const analyticsSlice = createSlice({
    name: 'analytics',
    initialState,
    reducers: {
        filterByUserId(state, action) {
            if(state.events.data.length > 0)
            state.events.data = [...current(state).eventsCache.data.filter(
                event => event.sender.userId === action.payload)]
        },
        filterEvents(state, action: {payload: {platform: string, userId?: string, from: number, to: number}}) {
            let {platform, userId, from, to} = {...action.payload}
            let data

            switch (userId) {
                case undefined:
                case 'all':
                    switch(platform){
                        case 'all':
                            data = [...current(state).eventsCache.data]
                            break
                        default:
                            data = [...current(state).eventsCache.data.filter(
                                event => event.data.platform === platform)]
                    }
                    break
                default:
                    switch(platform) {
                        case 'all':
                            data = [...current(state).eventsCache.data.filter(
                                event => (event.sender.userId === userId))]
                            break
                        default:
                            data = [...current(state).eventsCache.data.filter(
                                event => (
                                    event.data.platform === platform && event.sender.userId === userId))]     
                    }
            }
            state.events.data = data.filter(data => data.time >= from && data.time <= to)
        },
        filterByTime(state, action: {payload: {from: number, to: number}}) {
            let {from, to} = {...action.payload}
            state.events.data = [...current(state).events.data.filter(
                event => event.time >= from && event.time <= to
            )]
        }
    },
    extraReducers: (builder) => {
        builder.addCase(postAnalyticsEvent.pending, (state) => {
            state.events.pending = true;
        })
        builder.addCase(postAnalyticsEvent.fulfilled, (state) => {
            state.events.pending = false;
        })
        builder.addCase(postAnalyticsEvent.rejected, (state) => {
            state.events.pending = false;
        })
        builder.addCase(fetchEventsByUserId.pending, (state) => {
            state.events.pending = true;
        })
        builder.addCase(fetchEventsByUserId.fulfilled, (state, action) => {
            state.events.data = [...action.payload];
            state.events.pending = false;
        })
        builder.addCase(fetchEventsByUserId.rejected, (state) => {
            state.events.data = []
            state.events.pending = false;
        })
        builder.addCase(fetchEventsByOrganizationId.pending, (state) => {
            state.events.pending = true;
        })
        builder.addCase(fetchEventsByOrganizationId.fulfilled, (state, action) => {
            if(action.payload) {
                state.events.data = [...action.payload];
                state.eventsCache.data = [...action.payload];
                state.events.pending = false;
                return
            }
            state.events.data = []
            state.eventsCache.data = []
            state.events.pending = false;
        })
        builder.addCase(fetchEventsByOrganizationId.rejected, (state) => {
            state.events.data = []
            state.events.pending = false;
        })
        builder.addCase(fetchEventsByUserIdSortedByName.pending, (state) => {
            state.eventsByName.pending = true;
        })
        builder.addCase(fetchEventsByUserIdSortedByName.fulfilled, (state, action) => {
            state.eventsByName.data = [...action.payload];
            state.eventsByName.pending = false;
        })
        builder.addCase(fetchEventsByUserIdSortedByName.rejected, (state) => {
            state.eventsByName.pending = false;
        })
    }
})

export const {
    filterByUserId,
    filterEvents,
    filterByTime
} = analyticsSlice.actions

export default analyticsSlice.reducer;