import { 
    createSlice, 
    createAsyncThunk 
} from '@reduxjs/toolkit'
import { store } from '../store'
import { DB_ENDPOINT } from '../db'
import { encodeRequestBody } from '../helpers'
import { 
    AdminUpdateUserPasswordArgs, 
    DefaultState, 
    PostArgs, 
    PutArgs, 
    UpdateUserPasswordArgs 
} from './interfaces'

const ROUTE = '/user-management';
const PATH = DB_ENDPOINT + ROUTE;

const getHeaders = (contentType: string) => {
    if(!store.getState().tokens.accessToken) return;
    var token = store.getState().tokens.accessToken;
    var headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': contentType,
    }
    return headers;
};

export const fetchUsers = createAsyncThunk(
    'users/fetchUsers',
    async() => {
        return fetch(PATH + `/user`,
        { 
            method: 'GET', 
            headers: getHeaders('application/x-www-form-urlencoded'),
        }  
        )
        .then(res => res.json());
    }
);

export const fetchUserDetails = createAsyncThunk(
    'users/fetchUserDetails',
    async(id: String) => {
        return fetch(PATH + `/user/details/${id}`,
        { 
            method: 'GET', 
            headers: getHeaders('application/x-www-form-urlencoded'),
        }  
        )
        .then(res => res.json());
    }
);

export const fetchUserEditDetails = createAsyncThunk(
    'users/fetchUserEditDetails',
    async(id: String) => {
        return fetch(PATH + `/user/details/${id}`,
        { 
            method: 'GET', 
            headers: getHeaders('application/x-www-form-urlencoded'),
        }  
        )
        .then(res => res.json());
    }
);

export const fetchUsersByOrganizationId = createAsyncThunk(
    'users/fetchUsersByOrganizationId',
    async(id: string) => {
        return fetch(PATH + `/user/organization/${id}`,
        { 
            method: 'GET',
            headers: getHeaders('application/x-www-form-urlencoded'),
        }  
        )
        .then(res => res.json());
    }
);

export const postUser = createAsyncThunk(
    'users/postUser',
    async(args: PostArgs) => {
        return fetch(PATH + '/user/create',
            {
                method: 'POST',
                headers: getHeaders('application/json'),
                body: JSON.stringify({...args, email: args.email.toLowerCase()})
            }
        )
        .then(res => res.json())
        .catch(res => res.json());
    }
);

export const putUser = createAsyncThunk(
    'users/putUser',
    async(args: PutArgs) => {
        args.email = args.email?.toLowerCase()
        return fetch(PATH + `/user/update/${args.id}`,
            { 
                method: 'PUT', 
                headers: getHeaders('application/json'),
                body: JSON.stringify({...args})
            }
        )
        .then(res => res.json())
        .catch(res => res.json());
    }
);

export const removeUser = createAsyncThunk(
    'users/removeUser',
    async(id: string) => {
        return fetch(PATH + `/user/delete/${id}`,
            {
                method: 'DELETE',
                headers: getHeaders('application/x-www-form-urlencoded'),
            }
        )
        .then(res => res.json())
        .catch(res => res.json());
    }
);

export const adminUpdateUserPassword = createAsyncThunk(
    'users/adminUpdateUserPassword',
    async(args: AdminUpdateUserPasswordArgs) => {
        let requestBody = encodeRequestBody(args)
        return fetch(PATH + `/user/admin/change/password`,
            {
                method: 'PUT',
                headers: getHeaders('application/x-www-form-urlencoded'),
                body: requestBody
            }
        )
        .then(res => res.json())
        .catch(res => res.json());
    }
);

export const updateUserPassword = createAsyncThunk(
    'users/updateUserPassword',
    async(args: UpdateUserPasswordArgs) => {
        let requestBody = encodeRequestBody(args)
        return fetch(PATH + `/user/change/password`,
            {
                method: 'PUT',
                headers: getHeaders('application/x-www-form-urlencoded'),
                body: requestBody
            }
        )
        .then(res => res.json())
        .catch(res => res.json());
    }
);

export const fetchParticipantUsers = createAsyncThunk(
    'usersFetchParticipantUsers',
    async(userId: string) => {
        return fetch(PATH + `/user/participantUsers/${userId}`,
        {
            method: 'GET',
            headers: getHeaders('application/x-www-form-urlencoded')
        }
        )
        .then(res => res.json())
        .catch(res => res.json());
    }
);

const initialState: DefaultState = {
    pending: false,
    data: {
        allUsers: [],
        participantUsers: [],
        newUser: undefined,
        userDetails: null,
        userEditDetails: null,
    },
};

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUsers.pending, (state) => {
            state.pending = true;
        })
        builder.addCase(fetchUsers.fulfilled, (state, action) => {
            state.data.allUsers = action.payload.users;
            state.pending = false;
        })
        builder.addCase(fetchUserDetails.pending, (state) => {
            state.pending = true;
        })
        .addCase(fetchUserDetails.fulfilled, (state, action) => {
            state.data.userDetails = action.payload;
            state.pending = false;
        })
        .addCase(fetchUserEditDetails.pending, (state) => {
            state.pending = true;
        })
        .addCase(fetchUserEditDetails.fulfilled, (state, action) => {
            state.data.userEditDetails = action.payload;
            state.pending = false;
        })
        .addCase(fetchUserEditDetails.rejected, (state) => {
            state.pending = false;
        })
        .addCase(fetchUsersByOrganizationId.pending, (state) => {
            state.pending = true;
        })
        .addCase(fetchUsersByOrganizationId.fulfilled, (state, action) => {
            state.data.allUsers = action.payload.users;
            state.pending = false;
        })
        .addCase(putUser.pending, (state) => {
            state.pending = true;
        })
        .addCase(putUser.fulfilled, (state) => {
            state.pending = false;
        })
        .addCase(postUser.pending, (state) => {
            state.pending = true;
        })
        .addCase(postUser.fulfilled, (state, action) => {
            state.data.newUser = action.payload.user
            state.pending = false;
        })
        .addCase(removeUser.pending, (state) => {
            state.pending = true;
        })
        .addCase(removeUser.fulfilled, (state) => {
            state.pending = false;
        })
        .addCase(adminUpdateUserPassword.pending, (state) => {
            state.pending = true;
        })
        .addCase(adminUpdateUserPassword.fulfilled, (state) => {
            state.pending = false;
        })
        .addCase(updateUserPassword.pending, (state) => {
            state.pending = true;
        })
        .addCase(updateUserPassword.fulfilled, (state) => {
            state.pending = false;
        })
        .addCase(fetchParticipantUsers.pending, (state) => {
            state.pending = true;
        })
        .addCase(fetchParticipantUsers.fulfilled, (state, action) => {
            state.data.participantUsers = action.payload;
            state.pending = false;
        })
    }
});

export default usersSlice.reducer;