import { store } from "../store";
import { resetError, setError } from "./errorsSlice";

export function removeError(){
    store.dispatch(resetError());
}

export function parseResForError(res: any, code?: number, message?: string) {
    if(!res.payload) return false;

    // Code and message are mutually exclusive.
    if(code && !message) return false;

    if(code && res.payload.code === code && message) {
        store.dispatch(setError({error: message}));
        return true;
    };

    if(res.payload.error) {
        store.dispatch(setError(res.payload));
        return true;
    };

    return false;
}