import { useParticipants } from "../../redux/hooks";
import { getReportsByRange } from "../../redux/reports/reportsHelpers";
import { useEffect, useState } from "react";
import { AnalyticsTag, AnalyticsEventName, ClickParams } from "../../analytics";
import { CreateAnalyticsEvent } from "../../redux/analytics/analyticsHelpers";
import { DatePickerSingle } from "./DatePickerSingle";

const DAY = 1000 * 60 * 60 * 24;
const DEFAULT_RANGE = 30 * DAY;

function today(): number {
    const today = new Date();
    today.setHours(24, 0, 0, 0);
    return today.getTime();
}

function now(): number {
    return new Date().getTime();
}

export const DateButtonsHeatMap: React.FC = () => {
    const { selectedParticipant } = useParticipants();
    const [selectedStartDate, setSelectedStartDate] = useState<Date>(
        new Date(today() - DEFAULT_RANGE)
    );
    const [selectedEndDate, setSelectedEndDate] = useState<Date>(
        new Date(now())
    );
    const clickEvent = CreateAnalyticsEvent<ClickParams>(
        AnalyticsEventName.DASHBOARD_GRAPHS,
        AnalyticsTag.CLICK
    );

    const selectRange = (from: number, to: number) => {
        if (!selectedParticipant.data?.id) return;

        if (to - from > 30 * DAY || from > to) {
            // Must trigger render for date pickers to update
            from -= Math.round((Math.random() * 10));
            to = from + 30 * DAY;
        }
        setSelectedStartDate(new Date(from));
        setSelectedEndDate(new Date(to));
        getReportsByRange(selectedParticipant.data.id, from, to);
    };

    const selectStartDate = (from: number) => {
        selectRange(from, selectedEndDate.getTime());
    };

    const selectEndDate = (to: number) => {
        selectRange(selectedStartDate.getTime(), to);
    };

    useEffect(() => {
        selectRange(today() - DEFAULT_RANGE, now());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedParticipant]);

    if (!selectedParticipant.data) return <></>;

    return (
        <div
            style={{
                display: "flex",
                gap: "2em",
                padding: "2em 1em",
            }}
        >
            <DatePickerSingle
                selectedDate={selectedStartDate.toString()}
                defaultValue={selectedStartDate.getTime()}
                selectCallBack={(from) => selectStartDate(from)}
                label="Start Date"
                maxDateUnix={now()}
            />
            <DatePickerSingle
                selectedDate={selectedEndDate.toString()}
                defaultValue={selectedEndDate.getTime()}
                selectCallBack={(_, to) => selectEndDate(to)}
                label="End Date"
                minDateUnix={selectedStartDate.getTime()}
                maxDateUnix={now()}
            />
        </div>
    );
};
