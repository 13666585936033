import { useEffect, useState } from "react";
import { StyleSheet } from "../../assets/styles";
import { User } from "../../interfaces/User";
import { useUsers } from "../../redux/hooks";
import { selectParticipant } from "../../redux/participants/participantsHelpers";
import { ComboBox, Options } from "./ComboBox"
import { sortOptionsAlphabetically } from "./utils";

interface Props {
    callback: (id: string) => void
    disabled?: boolean
    style?: StyleSheet
}

export const AnalyticsUserSelection: React.FC<Props> = ({callback, disabled, style}) => {
    const { allUsers } = useUsers();
    const [dropDownOptions, setDropDownOptions] = useState<Options[]>([{label: "None", value: "none"}]);

    // Sort user data into options for combo box
    useEffect(() => {
        if(allUsers.length > 0) {
            let options: Options[] = allUsers.reduce((acc: any[], user: User) => {
                    acc.push({
                        label: user.email,
                        value: user.id
                    })
                    return acc
                },[])
            options = sortOptionsAlphabetically(options)
            options.unshift({label: "All", value: "all"})
            setDropDownOptions(options)
        }
    },[allUsers])

    const selectDropDown = (selection: Options | null) => {
        if(selection?.value && allUsers.length > 0) {
            callback(selection.value);
            selectParticipant(selection.value, false);
        }
    }

    return (
        <>
        {
            <div style={{...style}}>
                <ComboBox
                    label={'User'} 
                    options={dropDownOptions} 
                    callback={selectDropDown}
                    disabled={disabled}
                />
            </div>
        }
        </>
    )
}