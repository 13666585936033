import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { DB_ENDPOINT } from '../db'
import { encodeRequestBody } from '../helpers'
import { store } from '../store'
import { DefaultState, PostArgs, PutArgs } from './interfaces'

const ROUTE = '/user-management'
const PATH = DB_ENDPOINT + ROUTE

const getHeaders = () => {
    if(!store.getState().tokens.accessToken) return;

    var token = store.getState().tokens.accessToken
    var headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
    }
    return headers
};

export const fetchOrganizations = createAsyncThunk(
    'organizations/fetchOrganizations',
    async() => {
        return fetch(PATH + `/organization/`,
        { 
            method: 'GET', 
            headers: getHeaders(),
        }  
        )
        .then(res => res.json());
    }
);

export const putOrganization = createAsyncThunk(
    'organizations/putOrganization',
    async(args: PutArgs) => {
        let requestBody = encodeRequestBody(args)
        return fetch(PATH + `/organization/${args.id}`,
            { 
                method: 'PUT', 
                headers: getHeaders(),
                body: requestBody
            }  
        )
        .then(res => res.json())
        .catch(res => res.json())
    }
)

export const postOrganization = createAsyncThunk(
    'organizations/postOrganization',
    async(args: PostArgs) => {
        let requestBody = encodeRequestBody(args)
        return fetch(PATH + '/organization',
            {
                method: 'POST',
                headers: getHeaders(),
                body: requestBody
            }
        )
        .then(res => res.json())
        .catch(res => res.json())
    }
)

export const removeOrganization = createAsyncThunk(
    'organizations/removeOrganization',
    async(args: {id: string}) => {
        return fetch(PATH + `/organization/remove/${args.id}`,
            {
                method: 'DELETE',
                headers: getHeaders(),
            }
        )
        .then(res => res.json())
        .catch(res => res.json())
    }
)

const initialState: DefaultState = {
    allOrganizations: {
        pending: false,
        data: []
    },
    selectedOrganization: {
        data: null,
        pending: false
    }
}

const organizationsSlice = createSlice({
    name: 'organizations',
    initialState,
    reducers: {
        resetOrganizationsState(state) {
            state.allOrganizations.data = [];
            state.selectedOrganization.data = null;
        },
        selectOrganizationById(state, action) {
            let organization: any = state.allOrganizations.data.find(organization => {
                return organization.id === action.payload;
            })
            if(organization)
            state.selectedOrganization.data = organization;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchOrganizations.pending, (state) => {
            state.allOrganizations.pending = true;
        })
        builder.addCase(fetchOrganizations.fulfilled, (state, action) => {
            if(action.payload.organizations)
            state.allOrganizations.data = action.payload.organizations;
            state.allOrganizations.pending = false;
        })
        builder.addCase(putOrganization.pending, (state) => {
            state.allOrganizations.pending = true;
        })
        builder.addCase(putOrganization.fulfilled, (state) => {
            state.allOrganizations.pending = false;
        })
        builder.addCase(postOrganization.pending, (state) => {
            state.allOrganizations.pending = true;
        })
        builder.addCase(postOrganization.fulfilled, (state) => {
            state.allOrganizations.pending = false;
        })
        builder.addCase(removeOrganization.pending, (state) => {
            state.allOrganizations.pending = true;
        })
        builder.addCase(removeOrganization.fulfilled, (state) => {
            state.allOrganizations.pending = false;
        })
    }
})

export const { 
    resetOrganizationsState, 
    selectOrganizationById 
} = organizationsSlice.actions;

export default organizationsSlice.reducer;