import { Participant } from "../../interfaces/Participant";
import { useParticipants } from "../../redux/hooks";
import { Pairing, PairingStatus } from "../../redux/pairing/pairingSlice";

export function description(participantId: string, participants: Participant[]) {
    return (
        participants.find(
            (participant) => participant.id === participantId
        )?.description || "NA"
    );
}

export const PairingDetails = (pairing: Pairing) => {
    const { allParticipants } = useParticipants();

    return (
        <div style={styles.container}>
            <h1>Last Paired</h1>
            <div style={styles.details}>
                <div style={styles.lastPairedDetailRow}>
                    <strong>Wearable ID: </strong>
                    {pairing.wearable_identifier}
                </div>
                <div style={styles.lastPairedDetailRow}>
                    <strong>Participant: </strong>
                    {description(pairing.participant_id, allParticipants.data)}
                </div>
                <div style={styles.lastPairedDetailRow}>
                    <strong>Pairing Status: </strong>
                    {PairingStatus[pairing.pairing_status]}
                    <br />
                </div>
                <div style={styles.lastPairedDetailRow}>
                    <strong>Pairing PIN: </strong>
                    {pairing.pairing_pin}
                    <br />
                </div>
                <div style={styles.lastPairedDetailRow}>
                    <strong>Created At: </strong>
                    {pairing.created_at.toString()}
                    <br />
                </div>
                <div style={styles.lastPairedDetailRow}>
                    <strong>Updated At: </strong>
                    {pairing.updated_at.toString()}
                    <br />
                </div>
            </div>
        </div>
    );
};

const styles: { [key: string]: React.CSSProperties } = {
    container: {
        flex: 6,
        margin: "1em",
        padding: "2em",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        alignItems: "space-between",
    },
    details: {
        display: "flex",
        flexDirection: "column",
        rowGap: "0.5em",
    },
};
