import '../../../components/Forms/Forms.css'
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { SitesTable } from "./SitesTable";
import { CreateSite } from "./CreateSite";
import { EditSite } from "./EditSite";

export const Sites: React.FC = () => {
        let { path } = useRouteMatch();

    return (
        <>
        <Switch>
            <Route exact path={path}>
                <SitesTable />
            </Route>
            <Route path={`${path}/edit/:id`}>
                <EditSite />
            </Route>
            <Route path={`${path}/create`}>
                <CreateSite />
            </Route>
        </Switch>
        </>
    )
}
