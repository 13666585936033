import { store } from '../store';
import { FetchArgs, PostArgs, PutArgs } from './interfaces';
import { fetchAllCareTeamsByUserId, fetchCareTeamById, fetchCareTeamByUserId, fetchCareTeams, postCareTeam, putCareTeam, removeCareTeam, removeEnrolledCareTeams, removeSelectedCareTeam, selectCareTeamById } from './careTeamsSlice';
import { validateTokenExpiry } from '../tokens/tokensHelpers';
import { parseResForError } from '../errors/errorHelpers';

export async function getCareTeamById(id: string) {
    await validateTokenExpiry();
    await store.dispatch(fetchCareTeamById(id));
}

export async function getCareTeams(_organizationId: string | undefined = undefined) {
    await validateTokenExpiry();
    const { tokenData } = store.getState().tokens;


    // If no organizationId param, fetch careteams by token organizationId
    const organization_id = tokenData?.organization_id;
    const organizationId = _organizationId ? _organizationId : organization_id;

    if(!organizationId) return;

    const args: FetchArgs = { organizationId };
    await store.dispatch(fetchCareTeams(args));
}

export async function getCareTeamsByOrgId(organizationId: string) {
    await validateTokenExpiry();
    const args: FetchArgs = { organizationId };
    await store.dispatch(fetchCareTeams(args));
}

export async function getCareTeamByUserId(userId: string) {
    await validateTokenExpiry();
    await store.dispatch(fetchCareTeamByUserId(userId));
}

export async function getAllCareTeamsByUserId(userId: string) {
    await validateTokenExpiry();
    await store.dispatch(fetchAllCareTeamsByUserId(userId));
}

export function selectCareTeam(careTeamId: string) {
    if(careTeamId)
    store.dispatch(selectCareTeamById(careTeamId));
}

export async function handleCareTeamRefresh() {
    const organizationId = store.getState().organizations.selectedOrganization.data?.id;
    if(organizationId) {
        getCareTeamsByOrgId(organizationId);
    } else {
        getCareTeams();
    }
}

export async function createCareTeam(args: PostArgs) {
    await validateTokenExpiry()
    await store.dispatch(postCareTeam(args))
    .then(res => parseResForError(res));
    await handleCareTeamRefresh();
}

export async function updateCareTeam(args: PutArgs) {
    await validateTokenExpiry();
    await store.dispatch(putCareTeam(args))
    .then(res => parseResForError(res));
    return await handleCareTeamRefresh();
}

export async function deleteCareTeam(id: string) {
    await validateTokenExpiry();
    return await store.dispatch(removeCareTeam({id}))
    .then(res => parseResForError(res, 405,
        `
        The care team could not be deleted, 
        please ensure there are no users or participants assigned to
        this care team.
        `
    ));
}

export async function deselectCareTeam() {
    store.dispatch(removeSelectedCareTeam());
}

export function deselectEnrolledCareTeams() {
    store.dispatch(removeEnrolledCareTeams());
}