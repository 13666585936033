import { useCallback, useEffect, useState } from "react"
import { WearableType } from "../../interfaces/Wearables"
import { Roles, useRoles, useWearables } from "../../redux/hooks"
import { getWearableTypes } from "../../redux/wearables/wearablesHelpers"
import { ComboBox, Options } from "./ComboBox"

interface Props {
    callback: (id: string) => void;
    defaultId?: string;
}

export const WearableTypeSelection: React.FC<Props> = ({callback, defaultId}) => {
    const { wearableTypes } = useWearables();
    const [careTeamAccess] = useRoles(Roles.careteam);
    const [dropDownOptions, setDropDownOptions] = useState([{label: "None", value: "none"}]);

    const defaultValue = useCallback(() => {
        if(defaultId) {
            let wearableType = wearableTypes.data.find(type => type.id === defaultId)
            if(wearableType)
            return {
                label: wearableType.name,
                value: wearableType.id
            }
        }
        return null;
    },[defaultId, wearableTypes.data])

    useEffect(() => {
        getWearableTypes()
    },[])

    // Create options for drop down list
    useEffect(() => {
        let options = wearableTypes.data.reduce((acc: any[], wearableType: WearableType) => {
            // Work around for late stage feature update
                if(wearableType.name !== "Fitbit Sense")
                acc.push({
                    label: wearableType.name,
                    value: wearableType.id
                })
                return acc
            },[])
        setDropDownOptions(options)
    },[wearableTypes.data])

    const selectDropDown = (selection: Options | null) => {
        if(selection?.value) {
            callback(selection.value);
        }
    }

    return (
        <>
        {
            careTeamAccess &&
            <div>
                <ComboBox 
                    label={'Wearable Type'} 
                    options={dropDownOptions} 
                    callback={selectDropDown}
                    defaultValue={defaultValue()}
                />
            </div>
        }
        </>
    )
}