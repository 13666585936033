import { store } from "../store";
import { validateTokenExpiry } from "../tokens/tokensHelpers";
import { fetchAllPairings, verifyPairing } from "./pairingSlice";

export async function VerifyPairing(args: { pin: string, participant_id: string }) {
    await validateTokenExpiry();
    return await store.dispatch(verifyPairing(args));
}

export async function getAllPairings() {
    await validateTokenExpiry();
    return await store.dispatch(fetchAllPairings());
}

export const getHeaders = () => {
    if(!store.getState().tokens.accessToken) return;

    var token = store.getState().tokens.accessToken;

    var headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
    } 
    return headers;
}
