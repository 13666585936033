import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Button, Checkbox, FormControlLabel, FormGroup, TextField, TextFieldProps } from "@mui/material"
import { useEffect, useState } from "react"
import { Link, useHistory, useParams } from "react-router-dom"
import { ParticipantSelection } from "../../../components/ComboBox/ParticipantSelection"
import { WearableTypeSelection } from "../../../components/ComboBox/WearableTypeSelection"
import { AcceptModal } from "../../../components/Modals/AcceptModal"
import { AcceptRejectModal } from "../../../components/Modals/AcceptRejectModal"
import { useWearables } from "../../../redux/hooks"
import { adminChangeWearablePassword, updateWearable } from "../../../redux/wearables/wearablesHelpers"
import { macAddresssWarning, passwordWarning, validateMacAddress, validatePassword } from "../../../validation/Validate"
import { WearablesJoin } from "./WearablesTable"

interface Params {
    id: string
}

export const EditWearable: React.FC = () => {
    const history = useHistory();
    let { id } = useParams<Params>();
    const wearables = useWearables().allWearables.data
    const wearable = wearables.find(w => w.id === id)
    const [identifier, setIdentifier] = useState(wearable?.identifier)
    const [wearableTypeId, setWearableTypeId] = useState('')
    const [participantId, setParticipantId] = useState('')
    const [activeFrom, setActiveFrom] = useState<string | null>(wearable?.activeFrom!)
    const [activeTo, setActiveTo] = useState<string | null>(wearable?.activeTo!)
    const [password, setPassword] = useState('')
    const [changePass, setChangePass] = useState(false)
    const [verificationWarningOpen, setVerificationWarningOpen] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');

    //When page is refreshed, state is cleared and will no longer be able to
    // get wearable by id param
    if(!wearable) history.push('/admin/wearables')

    useEffect(() => {
    })
    useEffect(() => {
        if(wearable) {
            let _wearable = wearable as unknown
            let __wearable = _wearable as WearablesJoin
            setParticipantId(__wearable.participant_id);
            setWearableTypeId(__wearable.wearable_type_id);
            setActiveFrom(__wearable.active_from);
            setActiveTo(__wearable.active_to);
        }
    },[wearable])

    const parseDate = (date: object, fn: Function) => {
        let timestamp = new Date(date.toString())
        let y = timestamp.getFullYear()
        let m = timestamp.getMonth() + 1
        let d = timestamp.getDate()
        let h = timestamp.getHours()
        let min = timestamp.getMinutes()
        let s = timestamp.getSeconds()
        let ms = timestamp.getMilliseconds()
        let formatted = `${y}-${m}-${d} ${h}:${min}:${s}.${ms}`
        fn(formatted);
    }

    const handleChange = (field: string, value: string) => {
        switch(field) {
            case 'identifier':
                setIdentifier(value);
                return;
            case 'wearableTypeId':
                setWearableTypeId(value);
                return;
            case 'participantId':
                setParticipantId(value);
                return;
            case 'activeFrom':
                setActiveFrom(value);
                if(!activeTo) {
                    setActiveTo(value)
                }
                return;
            case 'activeTo':
                setActiveTo(value);
                return;
            case 'password':
                setPassword(value);
                return;
        }
    }

    const handleSubmit = () => {
        if(changePass && !validatePassword(password)) {
            setWarningMessage(passwordWarning);
            setVerificationWarningOpen(true);
            return;
        }
        if(!validateMacAddress(identifier!)) {
            setWarningMessage(macAddresssWarning);
            setVerificationWarningOpen(true);
            return;
        }
        if(
            !activeFrom || !activeTo || !identifier || 
            !participantId || !wearableTypeId
        ) {
            setWarningMessage("Please fill out all the required fields.");
            setVerificationWarningOpen(true);
            return;
        }

        updateWearable({
            id,
            identifier, 
            participantId, 
            wearableTypeId,
            activeFrom,
            activeTo
        })
        if(changePass && password.length > 0) {
            adminChangeWearablePassword({
                id: id,
                newPassword: password
            })
        }
        history.push('/admin/wearables')
    }

    return (
        <div className="form-page-wrapper">
            <AcceptModal 
                message={warningMessage}
                isOpen={verificationWarningOpen}
                callBack={() => setVerificationWarningOpen(false)}
            />
            <div className='form-wrapper'>
                <h2 className="form-header">Update Wearable</h2>
                <p className="form-description">
                    Fill out the fields below and click 'Update', or click 'Cancel' to go back.
                </p>
                    <TextField
                        required
                        label="Identifier"
                        value={identifier}
                        sx={{marginTop: '0.5em'}}
                        onChange={(e) => handleChange('identifier', e.target.value)}
                    />
                    <div style={{height: '1.5em'}}></div>
                    <ParticipantSelection 
                        callback={(id: string) => handleChange('participantId', id)}
                        defaultId={participantId}
                    />
                    <div style={{height: '1.5em'}}></div>
                    <WearableTypeSelection
                        callback={(id: string) => handleChange('wearableTypeId', id)}
                        defaultId={wearableTypeId}
                    />
                    <DatePicker
                        label="Active From"
                        value={activeFrom}
                        onChange={(newValue: object | null) => {
                        newValue && parseDate(newValue, setActiveFrom)}}
                        renderInput={(params: TextFieldProps) => 
                            <TextField 
                            color="primary" 
                            sx={{marginTop: '1.5em'}}
                            {...params} 
                        />}
                    />
                    <DatePicker
                        label="Active To"
                        value={activeTo}
                        onChange={(newValue: object | null) => {
                        newValue && parseDate(newValue, setActiveTo);
                        }}
                        renderInput={(params: TextFieldProps) => 
                            <TextField 
                            color="primary" 
                            sx={{marginTop: '1.5em'}}
                            {...params} 
                        />}
                    />
                    <TextField
                        required
                        type="password"
                        label="Password"
                        disabled={!changePass}
                        value={password}
                        sx={{
                            marginTop: '1.5em', 
                            marginBottom: '1em',
                        }}
                        onChange={(e) => handleChange('password', e.target.value)}
                    />
                    <FormGroup>
                        <FormControlLabel 
                        control={<Checkbox />} 
                        label="Change Password"
                        onChange={() => setChangePass(!changePass)}
                        />
                    </FormGroup>
                    <div className="form-buttons-wrapper">
                        <Link to={'#'} onClick={() => history.push(`/admin/wearables`)}>
                            <Button 
                                variant="contained"
                            >
                                Cancel
                            </Button>
                        </Link>   
                        <Link to={'#'}>
                            <AcceptRejectModal
                                openLabel='Update'
                                openDisabled={false}
                                acceptLabel='Yes'
                                acceptColor='red'
                                rejectLabel='No'
                                message='Are you sure you want to update this record?'
                                callBack={handleSubmit}
                            />
                        </Link>
                    </div>
            </div>
        </div>
    )
}