import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { VISUALIZATION_ENDPOINT } from "../db";
import { getHeaders } from "../helpers";
import { getTimeZone } from "./dataVisualizationsHelpers";
import { DefaultState, FetchArgs } from './interfaces';

const PATH = VISUALIZATION_ENDPOINT

export const fetchAllResponses = createAsyncThunk(
    'visualizations/fetchAllResponses',
    async(args: FetchArgs) => {
        const { participantId, userId, startAt, endAt } = args
        getTimeZone()
        return fetch(PATH + `/responses/stats/all?participantId=${participantId}&userId=${userId}&startAt=${startAt}&endAt=${endAt}&timeAdjustment=${getTimeZone()}`,
            {
                method: 'GET',
                headers: getHeaders()
            }
        )
        .then(res => {
            return res.json()
        })
    }
)

export const fetchMonthlyResponses = createAsyncThunk(
    'visualizations/fetchMonthlyResponses',
    async(args: FetchArgs) => {
        const { participantId, startAt, endAt } = args
        return fetch(PATH + `/responses/stats/monthly?participantId=${participantId}&startAt=${startAt}&endAt=${endAt}`,
            {
                method: 'GET',
                headers: getHeaders()
            }
        )
        .then(res => {
            return res.json()
        })
    }
)

export const clearState = createAsyncThunk(
    'visualizations/clearState',
    async() => {return}
)

const initialState: DefaultState = {
    allResponses: {
        pending: false,
        data: [
            {
                basic: [],
                hourly: [],
                daily: [],
                weekly: [],
                monthly: []
            }
        ]
    },
    monthlyResponses: {
        pending: false,
        data: []
    }
}

const visualizationsSlice = createSlice({
    name: 'visualizations',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllResponses.pending, (state) => {
            state.allResponses.data = initialState.allResponses.data
            state.allResponses.pending = true
        })
        builder.addCase(fetchAllResponses.fulfilled, (state, action) => {
            state.allResponses.data = action.payload
            state.allResponses.pending = false
        })
        builder.addCase(fetchMonthlyResponses.pending, (state) => {
            state.monthlyResponses.pending = true
        })
        builder.addCase(fetchMonthlyResponses.fulfilled, (state, action) => {
            state.monthlyResponses.data = action.payload
            state.monthlyResponses.pending = false
        })
    }
})

export default visualizationsSlice.reducer