import { store } from '../store';
import { FetchArgs } from './interfaces';
import { 
    fetchAnxietyCounts, 
    fetchCaregiverBetaReports, 
    fetchLatestReport, 
    fetchOrganizationalBetaReports, 
    fetchParticipantBetaReports, 
    fetchReportById, 
    fetchReportsByRange, 
    fetchTeamBetaReports, 
    setReportStartEnd, 
    setReportTimeRange
} from './reportsSlice';
import Report from '../../interfaces/Report';
import { fetchNotificationsByRange } from '../notifications/notificationsSlice';
import { validateTokenExpiry } from '../tokens/tokensHelpers';

export async function getReportById(reportId: string) {
    await validateTokenExpiry()
    const args: FetchArgs = { reportId };
    await store.dispatch(fetchReportById(args));
};

export async function getLatestReport(participantId: string) {
    await validateTokenExpiry()
    const args: FetchArgs = { participantId };
    await store.dispatch(fetchLatestReport(args));
};

export async function getReportsByRange(participantId: string, startAt: number, endAt: number) {
    await validateTokenExpiry()
    const args: FetchArgs = { participantId, startAt, endAt };
    await store.dispatch(fetchReportsByRange(args));
    await store.dispatch(fetchNotificationsByRange(args));
    store.dispatch(setReportStartEnd({startAt, endAt}));
    store.dispatch(setReportTimeRange(daysBetween(startAt, endAt)));
};

export async function getAnxietyCounts(participantId: string, startAt: number, endAt: number) {
    await validateTokenExpiry()
    const args: FetchArgs = { participantId, startAt, endAt };
    await store.dispatch(fetchAnxietyCounts(args));
};

export async function getBetaReportsOrganization(organizationId: string) {
    await validateTokenExpiry()
    await store.dispatch(fetchOrganizationalBetaReports(organizationId))
}

export async function getBetaReportsParticipant(organizationId: string) {
    await validateTokenExpiry()
    await store.dispatch(fetchParticipantBetaReports(organizationId))
}

export async function getBetaReportsCareGiver(organizationId: string) {
  await validateTokenExpiry()
  await store.dispatch(fetchCaregiverBetaReports(organizationId))
}

export async function getBetaReportsTeams(organizationId: string) {
  await validateTokenExpiry()
  await store.dispatch(fetchTeamBetaReports(organizationId))
}

const anxietyStates = ["NORMAL", "LOW", "MEDIUM", "HIGH"]

export function sortTimeSeriesData(reports: Report[]) {

    if(!reports || reports.length === 0) return;

    var report_buffer: any[] = []
    reports.forEach(report => {
        report_buffer.push(report)
    })

    var sorted_reports: Report[] = report_buffer.sort((a, b) => {
        return a.time - b.time
    })

    var timeseriesData: any = [];

    sorted_reports.map(report => {
        let pair = [];
        pair.push(report.time);
        // pair.push(report.anxietyLevel);
        pair.push(anxietyStates.indexOf(report.anxietyState));
        timeseriesData.push(pair);
        return null;
    })

    return timeseriesData;
};

export enum Weekday {
    Sun = 0,
    Mon = 1,
    Tue = 2,
    Wed = 3,
    Thu = 4,
    Fri = 5,
    Sat = 6
}

export interface DailyHeatMap {
    date: string,
    data: number[]
}

// Heat map data functions

function sortReportsByTimestamp(reports: Report[]) {
    var report_buffer: any[] = []
    reports.forEach(report => {
        report_buffer.push(report)
    })
    var sorted_reports: Report[] = report_buffer.sort((a, b) => {
        return a.time - b.time
    })
    return sorted_reports
}

const daysBetween = (from: number, to: number) => {
    return Math.ceil((to-from) / 86400000)
}


const truncateTimeStamp = (timestamp: number) => {
    let date = new Date(timestamp)
    let truncated = new Date(date.getFullYear(), date.getMonth(), date.getDate())
    return truncated.getTime()
}

const daysArray = (from: number, days_between_reports: number) => {
    let dates: number[] = []
    for(let i=0; i< days_between_reports; i++) {
        let timestamp = from + i * 86400000
        let date_truncated = truncateTimeStamp(timestamp)
        dates.push(date_truncated)
    }
    return dates
}

const dataByTimeStamp = (timestamp: number, data: Report[]) => {
    return data.filter(data => {
        return truncateTimeStamp(data.time) === timestamp
    })
}

const dataByHour = (hour: number, data: Report[]) => {
    return data.filter(data => {
        return new Date(data.time).getHours() === hour
    })
}

export const hoursArray = () => {
    var hours = []
    for(var i=0; i<=23; i++)
        hours.push(i)
    return hours
}

const hoursHashMap = (keys: number[], data: Report[]) => {
    let map = new Map<number, number>();
    for(let i=0; i<keys.length; i++) {
        let hourly_data = dataByHour(keys[i], data)
        if(hourly_data.length === 0) {
            map.set(keys[i], -1)
            continue
        }
        let total_anxiety_level = hourly_data.reduce((acc, i) => {
            acc += i.anxietyLevel
            return acc
        },0)

        if(total_anxiety_level === 0) {
            map.set(keys[i], 0)
            continue
        }

        let average_anxiety_level = Math.ceil(total_anxiety_level / hourly_data.length)
        map.set(keys[i], average_anxiety_level)
    }
    return map
}

const dataHashMap = (keys: number[], data: Report[]) => {
    let map = new Map<number, Map<number, number>>(); 
    for(let i=0; i< keys.length; i++) {
        let hourly_data = hoursHashMap(hoursArray(), dataByTimeStamp(keys[i], data))
        map.set(keys[i], hourly_data)
    }
    return map
}

export function sortHeatMapData(startAt: number | null, endAt: number | null, reportData: Report[]) {
    if(!startAt || !endAt) return;
    //TODO: A quick bug fix: const range; for some inexplicable reason, the default data selection with the heatmap date buttons
    // get calculated as 8 instead of 7 days despite using the same timestamp as verified in logs.
    // const range = selectedRange === 8 ? 7 : selectedRange;
    const data = sortReportsByTimestamp(reportData);
    //const [from, to] = timeRange(data);
    const day = 86400000
    var to = endAt
    var from = startAt
    const days_between_reports = daysBetween(from, to);
    const keys = daysArray(from, days_between_reports);
    
    const data_hash_map= dataHashMap(keys, data);
    return data_hash_map;
}