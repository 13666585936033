import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { colors } from './styles';

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: '#11cb5f',
    },
  },
});

export const Theme: React.FC<React.PropsWithChildren<{}>> = ({children}) => {
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
}