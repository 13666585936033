import { max } from "lodash"
import { Responses } from "../../interfaces/DataVisualization"

export const hourlyLabels = (data: Array<{hour: number}>) => data.reduce((prev: string[], curr) => {
    prev.push(`${curr.hour}:00`)
    return prev
},[])

export const calculateMaxTotals = (data: Responses[]) => {
    if(data.length === 0) return 0;
    const totals = data.reduce((prev: number[], curr) => {
       prev.push(curr.total)
       return prev
    },[])
    const maxTotal = max(totals)
    if(maxTotal) return maxTotal
    return 0
}

export const calculateTotal = (data: Responses[]) => {
    const total = data.reduce((prev: number, curr) => {
        prev += curr.total
        return prev
    },0)
    return total
}

export const calculateTotalsFromEmotion = (data: Responses[], key?: string, notes?: boolean) => {
    if(!key) return data

    const newTotals = data.reduce((prev: Responses[], curr) => {

        const _curr = {...curr}
        switch(key) {
            case 'happy':
                _curr.total = curr.happy 
                break
            case 'anxious':
                _curr.total = curr.anxious
                break
            case 'other':
                _curr.total = curr.other
                break
            // case 'notes':
            //     break
            case 'total':
                if(notes)
                _curr.total = curr.happy + curr.anxious + curr.other
                break
            default:
        }
        prev.push(_curr)
        return prev
    },[])

    return newTotals
}