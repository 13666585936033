import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { useParticipants} from '../../redux/hooks';
import { getReportsByRange } from '../../redux/reports/reportsHelpers';
import { useEffect, useState } from 'react';
import { colors } from '../../assets/styles';
import { DatePickerSingle } from "../../components/Dates/DatePickerSingle";
import { AnalyticsTag, AnalyticsEventName, ClickParams, DateParams, GraphParams} from '../../analytics';
import { CreateAnalyticsEvent } from '../../redux/analytics/analyticsHelpers';

export const DateButtonsTimeSeries: React.FC = () => {
    const { selectedParticipant } = useParticipants()
    const [selectedButton, setSelectedButton] = useState(0)
    const [selectedDate, setSelectedDate] = useState<string | undefined>(undefined);
    const clickEvent = CreateAnalyticsEvent<ClickParams>(AnalyticsEventName.DASHBOARD_GRAPHS, AnalyticsTag.CLICK)
    const selectDateEvent = CreateAnalyticsEvent<DateParams & ClickParams & GraphParams>(AnalyticsEventName.DASHBOARD_GRAPHS, AnalyticsTag.CLICK)

    const handleClickEvent = (button: string) => {
        clickEvent.Save({params: {
            action: 'select_time_range',
            button: button,
            page: 'dashboard',
        }})
    }

    const today = new Date()
    today.setHours(24, 0, 0, 0)
    let day = 1000 * 60 * 60 * 24
    const now = today.getTime()

    const selectRange = (buttonIndex: number, from: number, to: number) => {
        setSelectedButton(buttonIndex)
        if(selectedParticipant.data?.id) {
            setSelectedDate(new Date(from).toString())
            getReportsByRange(selectedParticipant.data.id, from, to)
        }
    }

    const selectSingleDate = (from: number, to: number) => {
        selectDateEvent.Save({params: {
            from: from,
            to: to,
            button: 'date_picker',
            graph: 'time_series',
            page: 'dashboard'
        }})
        selectRange(3, from, to)
    }
    
    const buttonEffects = (selection: number) => {
        return {
            color: selectedButton === selection ? colors.primary_button_text: colors.primary,
            backgroundColor: selectedButton === selection ? colors.primary : 'white',
            borderColor: colors.primary,
            ":hover": selectedButton === selection 
                ? {backgroundColor: colors.primary_hover, opacity: '0.8'} 
                : {borderColor: colors.primary, opacity: 0.8}
        }
    }

    useEffect(() => {
        if(selectedParticipant.data?.id) {
            setSelectedDate(new Date(now).toString())
            getReportsByRange(selectedParticipant.data.id, now-day, now)
        }
    },[])

    if(!selectedParticipant.data) return <></>

    return (
        <>
            <div style={{
                padding: '1em 1em'
            }}>
                <ButtonGroup 
                    variant="outlined" 
                    aria-label="outlined button group"
                    sx={{
                        height: '3.5em',
                        width: 'auto',
                        margin: '1em 1em'
                    }}
                >
                    <Button
                        variant={selectedButton === 0 ? 'contained': 'outlined'}
                        onClick={() => {handleClickEvent("today"); selectRange(0, now-day, now)}}
                        sx={buttonEffects(0)}
                    >
                        Today
                    </Button>
                    <Button
                        variant={selectedButton === 1 ? 'contained': 'outlined'}
                        onClick={() => {handleClickEvent("yesterday"); selectRange(1, (now-day) - day, now-day-1)}}
                        sx={buttonEffects(1)}
                    >
                        Yesterday
                    </Button>
                    <Button
                        variant={selectedButton === 2 ? 'contained': 'outlined'}
                        onClick={() => {handleClickEvent("7_days_ago"); selectRange(2, now-(8*day), now-(7*day)-1)}}
                        sx={buttonEffects(2)}
                    >
                        7 Days Ago
                    </Button>
                </ButtonGroup>
            </div>  
            <div style={{
                display: 'flex',
                padding: '2em 1em'
            }}>
                <DatePickerSingle 
                    selectedDate={selectedDate}
                    selectCallBack={selectSingleDate}
                />
            </div>   
        </>
    );
}