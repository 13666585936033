import { Button, TextField } from "@mui/material"
import { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { AcceptRejectModal } from "../../../components/Modals/AcceptRejectModal"
import { useOrganizations } from "../../../redux/hooks"
import { createSite } from "../../../redux/sites/sitesHelpers"

export const CreateSite: React.FC = () => {
    const history = useHistory();
    const {selectedOrganization } = useOrganizations()
    const organizationId = selectedOrganization.data?.id
    const [name, setName] = useState('')
    const [code, setCode] = useState('')

    if(!organizationId) history.push('/admin/sites')
    
    const handleChange = (field: string, value: string) => {
        switch(field) {
            case 'name':
                setName(value);
                return;
            case 'code':
                setCode(value);
                return;
        }
    }

    const handleSubmit = () => {
        if(!organizationId) return;
        createSite({name, code, organizationId})
        history.push('/admin/sites')
    }

    return (
        <div className="form-page-wrapper">
            <div className='form-wrapper'>
                <h2 className="form-header">Create New Site</h2>
                <p className="form-description">Fill out the fields below and click 'Create', or click 'Cancel' to go back.</p>
                    <TextField
                        required
                        label="Name"
                        value={name}
                        sx={{marginTop: '0.5em', marginBottom: '0.5em'}}
                        onChange={(e) => handleChange('name', e.target.value)}
                    />
                    <TextField
                        required
                        label="Code"
                        value={code}
                        sx={{marginTop: '0.5em', marginBottom: '0.5em'}}
                        onChange={(e) => handleChange('code', e.target.value)}
                    />
                    <div className="form-buttons-wrapper">
                        <Link to={'#'} onClick={() => history.goBack()}>
                            <Button 
                                variant="contained"
                            >
                                Cancel
                            </Button>
                        </Link>   
                        <Link to={'#'}>
                            <AcceptRejectModal
                                openLabel='Create'
                                openDisabled={false}
                                acceptLabel='Yes'
                                acceptColor='red'
                                rejectLabel='No'
                                message='Are you sure you want to create this record?'
                                callBack={handleSubmit}
                            />
                        </Link>
                    </div>
            </div>
        </div>
    )
}