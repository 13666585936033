import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { DB_ENDPOINT } from '../db'
import { DefaultState, UserRolePostArgs } from './interfaces'
import { store } from '../store'
import { encodeRequestBody } from '../helpers'

const ROUTE = '/user-management'
const PATH = DB_ENDPOINT + ROUTE

const getHeaders = () => {
    if(!store.getState().tokens.accessToken) return;

    var token = store.getState().tokens.accessToken;
    var headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
    } 
    return headers;
};

export const fetchRoles = createAsyncThunk(
    'userRoles/fetchRoles',
    async() => {
        return fetch(PATH + `/role`,
        { 
            method: 'GET', 
            headers: getHeaders(),
        }  
        )
        .then(res => res.json());
    }
);

export const fetchUserRolesByUserId = createAsyncThunk(
    'userRoles/fetchUserRolesByUserId',
    async(userId: string) => {
        return fetch(PATH + `/userrole/user/${userId}`,
        { 
            method: 'GET', 
            headers: getHeaders(),
        }  
        )
        .then(res => res.json());
    }
);

export const postUserRole = createAsyncThunk(
    'userRoles/postUserRole',
    async(args: UserRolePostArgs) => {
        let requestBody = encodeRequestBody(args)
        return fetch(PATH + `/userrole/`,
            { 
                method: 'POST', 
                headers: getHeaders(),
                body: requestBody
            }  
        )
        .then(res => res.json())
        .catch(res => res.json());
    }
);

const initialState: DefaultState = {
    roles: {
        data: [],
        pending: false
    },
    userRoles: {
        data: [],
        pending: false
    }
}

const rolesSlice = createSlice({
    name: 'userRoles',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(fetchRoles.pending, (state) => {
            state.roles.pending = true;
        });
        builder.addCase(fetchRoles.fulfilled, (state, action) => {
            state.roles.data = action.payload.roles;
            state.roles.pending = false;
        });
        builder.addCase(postUserRole.pending, (state) => {
            state.userRoles.pending = true;
        });
        builder.addCase(postUserRole.fulfilled, (state) => {
            state.userRoles.pending = false;
        });
        builder.addCase(fetchUserRolesByUserId.pending, (state) => {
            state.userRoles.pending = true;
        });
        builder.addCase(fetchUserRolesByUserId.fulfilled, (state, action) => {
            state.userRoles.data = action.payload.user_roles;
            state.userRoles.pending = false;
        });
    }
});

export default rolesSlice.reducer;