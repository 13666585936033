import { useEffect, useState } from "react";
import { AnalyticsPlatform } from "../../analytics";
import { StyleSheet } from "../../assets/styles";
import { ComboBox, Options } from "./ComboBox"
import { sortOptionsAlphabetically } from "./utils";

interface Props {
    callback: (id: string) => void
    disabled?: boolean
    style?: StyleSheet
}

export const PlatformSelection: React.FC<Props> = ({callback, disabled, style}) => {
    const [dropDownOptions, setDropDownOptions] = useState<Options[]>([{label: "None", value: "none"}]);
    
    // Sort user data into options for combo box
    useEffect(() => {
            let options: Options[] = Object.keys(AnalyticsPlatform).reduce((acc: any[], platform: string) => {
                    acc.push({
                        label: platform[0] + platform.slice(1).toLocaleLowerCase(),
                        value: platform,
                    })
                    return acc
                },[])
            options = sortOptionsAlphabetically(options)
            options.unshift({label: "All", value: "all"})
            setDropDownOptions(options)
    },[])

    const selectDropDown = (selection: Options | null) => {
        if(selection?.value) {
            callback(selection.value);
        }
    }

    return (
        <>
        {
            <div style={{...style}}>
                <ComboBox
                    label={'Platform'} 
                    options={dropDownOptions} 
                    callback={selectDropDown}
                    disabled={disabled}
                />
            </div>
        }
        </>
    )
}