import { Button, Input, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Roles, useOrganizations, useRoles, useUsers } from "../../../redux/hooks";
import Paper from '@mui/material/Paper';
import { Link, useRouteMatch } from "react-router-dom";
import { AcceptRejectModal } from "../../../components/Modals/AcceptRejectModal";
import AddIcon from '@mui/icons-material/Add';
import { OrganizationSelection } from "../../../components/ComboBox/OrganizationSelection";
import { deleteUser, getUsersByOrganizationId } from "../../../redux/users/usersHelpers";
import { useState } from "react";

export const UsersTable: React.FC = () => {
    let { url } = useRouteMatch();
    const data = useUsers().allUsers;
    const [dataFilter, setDataFilter] = useState(data);
    const [, authorities] = useRoles(Roles.organization);
    const selectedOrganization = useOrganizations().selectedOrganization.data;

    function filterUsersByEmail(email: string) {
        //set dataFilter to users with description that includes the search term
        setDataFilter(data.filter((user) => user.email.includes(email)));
    }

    return (
        <>
            <p style={{marginBottom: '0.5em', fontSize: 24}}>Users</p>
            <OrganizationSelection callback={getUsersByOrganizationId} />
            <Input 
                placeholder='Search by email'
                onChange={(e) => filterUsersByEmail(e.target.value)}
                sx={{marginTop: '2em', marginBottom: '1em', width: 299}}
            />
            {
                selectedOrganization &&
                <TableContainer 
                sx={{marginTop: '1.5em'}}
                component={Paper}
                >
                <Table aria-label={'Data table'}>
                    <TableHead>
                    <TableRow>
                        <TableCell >Email</TableCell>
                        <TableCell >Care Team</TableCell>
                        <TableCell >Created At</TableCell>
                        <TableCell sx={{width: '2em'}}></TableCell>
                        <TableCell sx={{width: '2em'}}></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {dataFilter && dataFilter.map((row) => (
                        <TableRow
                        key={row.id}
                        sx={{
                            "&:hover": {
                                background: "#efefef"
                            }
                        }}
                        >
                        <TableCell >{row.email}</TableCell>
                        <TableCell >{row.careTeamCode}</TableCell>
                        <TableCell >{row.createdAt}</TableCell>

                        <TableCell align='center'>
                            {  
                                authorities.includes('update') &&                          
                                <Link to={`${url}/edit/${row.id}`} style={{ textDecoration: 'none' }}>
                                    <Button 
                                        variant="contained"
                                    >
                                        Edit
                                    </Button>
                                </Link>
                            }
                        </TableCell>
                        <TableCell align='center'>
                            {
                                authorities.includes('delete') &&
                                <AcceptRejectModal
                                    openLabel='Delete'
                                    openColor='red'
                                    acceptLabel='Yes'
                                    acceptColor='red'
                                    rejectLabel='No'
                                    message='Are you sure you want to delete this record?'
                                    callBack={() => deleteUser(row.id)}
                                />
                            }
                        </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: '100%',
                        height: 'auto',
                    }}>
                        {
                            authorities.includes('write') &&
                            <Link to={`${url}/create`}>
                                <Button 
                                    variant="contained"
                                    onClick={() => {}}
                                    sx={{
                                        margin: '1em',
                                        paddingRight: '0.75em'
                                    }}
                                >
                                    New
                                    <AddIcon 
                                        sx={{
                                            fontSize: '1.3em',
                                            marginLeft: '0.3em',
                                            width: '1.35em'
                                        }}
                                    />
                                </Button>
                            </Link>
                        }
                    </div>
                </TableContainer>
            }
        </>
    )
}