import { useCallback, useEffect, useState } from "react";
import Role from "../../interfaces/Role";
import { Roles, useOrganizations, useRoles, useUserRoles } from "../../redux/hooks";
import { getRoles } from "../../redux/userRoles/userRolesHelpers";
import { ComboBox, Options } from "./ComboBox";

interface Props {
    callback: (id: string) => void;
    defaultId?: string;
    isEmployeeEmail: boolean;
}

const parseRole = (role: string) => {
    switch(role) {
        case 'ROLE_ADMIN': return 'Admin';
        case 'ROLE_CHAMPION': return 'Champion';
        case 'ROLE_MANAGER': return 'Manager';
        case 'ROLE_CARE_GIVER': return 'Care Giver';
        case 'ROLE_SELF_ADVOCATE': return 'Self Advocate';
        case 'ROLE_PAIRING_VENDOR': return 'Pairing Vendor';
        default: return 'None';
    }
}

export const RolesSelection: React.FC<Props> = ({callback, defaultId, isEmployeeEmail}) => {
    const { roles } = useUserRoles();
    const [organizationAccess] = useRoles(Roles.organization);
    const { selectedOrganization } = useOrganizations();
    const [dropDownOptions, setDropDownOptions] = useState([{label: "None", value: "none"}]);
    const [isEmployee, setIsEmployee] = useState(false)
    const [roleId, setRoleId] = useState<string | undefined>(undefined);

    useEffect(() => {
        setRoleId(defaultId)
    },[defaultId])

    useEffect(() => {
        let _isEmployee = 
        selectedOrganization.data?.name === 'Awake Labs'
        && selectedOrganization.data.code === 'AWK'
        && isEmployeeEmail;
        setIsEmployee(_isEmployee)
    },[isEmployeeEmail, selectedOrganization.data])    

    const defaultValue = useCallback(() => {
        if(roles.data.length > 0 && roleId) {
            let role = roles.data.find(role => role.id === roleId);
            if(!role) return null;
            return {
                label: parseRole(role.roleName),
                value: role.id
            }
        }
        return null;
    },[roles.data, roleId])

    useEffect(() => {
        getRoles()
    },[])

    // Create options for drop down list
    useEffect(() => {
            if(roles.data.length > 0) {
            let options = roles.data.reduce((acc: any[], role: Role) => {
                let label = parseRole(role.roleName);
                if(label === 'Admin') {
                    if(isEmployee) {
                        acc.push({
                            label: parseRole(role.roleName),
                            value: role.id
                        })
                        return acc;
                    } else {
                        return acc;
                    }
                }
                    acc.push({
                        label: parseRole(role.roleName),
                        value: role.id
                    })
                    return acc;
                },[])

            setDropDownOptions(options)
        }
    },[roles.data, isEmployee, roleId])

    const selectDropDown = (selection: Options | null) => {
        if(selection?.value) {
            callback(selection.value);
        }
    }

    return (
        <>
        {
            organizationAccess &&
            <div>
                <ComboBox 
                    label={'Role'} 
                    options={dropDownOptions} 
                    callback={selectDropDown}
                    defaultValue={defaultValue()}
                />
            </div>
        }
        </>
    )
}