import { parseResForError } from "../errors/errorHelpers";
import { store } from "../store";
import { validateTokenExpiry } from "../tokens/tokensHelpers"
import { 
    PostArgs, 
    PutArgs 
} from "./interfaces";
import { 
    fetchSitesByOrganizationId, 
    postSite, 
    putSite, 
    removeSite 
} from "./sitesSlice";

export async function getSitesByOrganizationId(id: string) {
    await validateTokenExpiry()
    await store.dispatch(fetchSitesByOrganizationId(id))
}

export async function updateSite(args: PutArgs) {
    await validateTokenExpiry()
    await store.dispatch(putSite(args))
    .then(res => parseResForError(res))
    return await store.dispatch(fetchSitesByOrganizationId(args.organizationId))
}

export async function createSite(args: PostArgs) {
    await validateTokenExpiry()
    await store.dispatch(postSite(args))
    .then(res => parseResForError(res))
    return await store.dispatch(fetchSitesByOrganizationId(args.organizationId))
}

export async function deleteSite(id: string, organizationId: string) {
    await validateTokenExpiry()
    await store.dispatch(removeSite(id))
    .then(res => parseResForError(res, 405,
        `
        The site could not be deleted, 
        please ensure there are no care teams assigned to this site.
        `
        ))
    return await store.dispatch(fetchSitesByOrganizationId(organizationId))
}