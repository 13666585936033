/**
 * @enum
 * 'timeseries' = 0
 * 'heatmap' = 1
 */
 export enum ChartTypes {
    'timeseries' = 0,
    'heatmap' = 1
  };

export interface DefaultState {
    charts: {
        selected: string
    }
};