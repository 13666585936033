import { useEffect } from "react"
import '../../../components/Forms/Forms.css'
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { CareTeamsTable } from "./CareTeamsTable";
import { handleCareTeamRefresh } from "../../../redux/careTeams/careTeamsHelpers";
import { EditCareTeam } from "./EditCareTeam";
import { CreateCareTeam } from "./CreateCareTeam";

export const CareTeams: React.FC = () => {
    let { path } = useRouteMatch();

    useEffect(() => {
        handleCareTeamRefresh()
    })

    return (
        <>
        <Switch>
            <Route exact path={path}>
                <CareTeamsTable />
            </Route>
            <Route path={`${path}/edit/:id`}>
                <EditCareTeam />
            </Route>
            <Route path={`${path}/create`}>
                <CreateCareTeam />
            </Route>
        </Switch>
        </>
    )
}
