import { useEffect, useState } from "react"
import { Organization } from "../../interfaces/Organization"
import { Roles, useOrganizations, useRoles } from "../../redux/hooks"
import { getOrganizations, selectOrganization } from "../../redux/organizations/organizationsHelpers"
import { ComboBox, Options } from "./ComboBox"
import { sortOptionsAlphabetically } from "./utils"

interface Props {
    callback: (id: string) => void;
}

export const OrganizationSelection: React.FC<Props> = ({callback}) => {
    const {allOrganizations, selectedOrganization} = useOrganizations()
    const [organizationId, setOrganizationId] = useState<null | string>(null)
    const [organizationAccess] = useRoles(Roles.organization)
    const [dropDownOptions, setDropDownOptions] = useState(
        [{label: "None", value: "none"}])

    const defaultValue = () => {
        if(selectedOrganization.data) {
            return {
                label: selectedOrganization.data.name,
                value: selectedOrganization.data.id
            }
        }
        return null;
    }

    useEffect(() => {
        if(selectedOrganization.data) {
            setOrganizationId(selectedOrganization.data.id)
        }
    },[selectedOrganization.data])

    useEffect(() => {
        if(organizationAccess && organizationId) {
            callback(organizationId);
        }
    },[organizationAccess, organizationId, callback])

    useEffect(() => {
        organizationAccess && getOrganizations()
    },[organizationAccess])

    // Create options for drop down list
    useEffect(() => {
        if(allOrganizations.data.length > 0) {
            let options = allOrganizations.data.reduce((acc: any[], organization: Organization) => {
                    acc.push({
                        label: organization.name,
                        value: organization.id
                    })
                    return acc
                },[])
            options = sortOptionsAlphabetically(options)
            setDropDownOptions(options)
        }
    },[allOrganizations.data])

    const selectDropDown = (selection: Options | null) => {
        if(selection?.value) {
            selectOrganization(selection.value);
        }
    }

    return (
        <>
        {
            organizationAccess &&
            <div>
                {/* <p style={{marginBottom: '1.5em'}}>Select an organization:</p> */}
                <ComboBox 
                    label={'Organization'} 
                    options={dropDownOptions} 
                    defaultValue={defaultValue()}
                    callback={selectDropDown}
                />
            </div>
        }
        </>
    )
}