import { createSlice } from '@reduxjs/toolkit'
import { DefaultState } from './interfaces'

const initialState: DefaultState = {
    error: '',
    active: false
}

const errorsSlice = createSlice({
    name: 'errors',
    initialState,
    reducers: {
        setError(state, action) {
            state.error = action.payload.error;
            state.active = true;
        },
        resetError(state) {
            state.error = '';
            state.active = false;
        },
    },
})

export const { 
    setError,
    resetError,
} = errorsSlice.actions

export default errorsSlice.reducer