import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { DB_ENDPOINT } from '../db'
import { encodeRequestBody } from '../helpers'
import { store } from '../store'
import { FetchArgs, DefaultState, PutArgs, PostArgs } from './interfaces'

const ROUTE = '/user-management'
const PATH = DB_ENDPOINT + ROUTE

const getHeaders = () => {
    if(!store.getState().tokens.accessToken) return;

    var token = store.getState().tokens.accessToken;
    var headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded'
    } 
    return headers;
}

export const fetchParticipantsByOrganizationId = createAsyncThunk(
    'participants/fetchParticipantsByOrganizationId',
    async(args: FetchArgs) => {
        const { organizationId } = args
        return fetch(PATH + `/participant/organization/${organizationId}`,
        { 
            method: 'GET', 
            headers: getHeaders(),
        }  
        )
        .then(res => res.json())
    }
)

export const fetchParticipantsByUserId = createAsyncThunk(
    'participants/fetchParticipantsByUserId',
    async(userId: string) => {
        return fetch(PATH + `/participant/user/${userId}`,
        {
            method: 'GET',
            headers: getHeaders(),
        },
        )
        .then(res => res.json())
    }
)

export const fetchParticipantWearablesByUserId = createAsyncThunk(
    'participants/fetchParticipantWearablesByUserId',
    async(userId: string) => {
        return fetch(PATH + `/user/participantUsers/${userId}`,
        {
            method: 'GET',
            headers: getHeaders(),
        },
        )
        .then(res => res.json())
    }
)

export const fetchParticipantsByCareTeamId = createAsyncThunk(
    'participants/fetchParticipantsByCareTeamId',
    async(args: FetchArgs) => {
        const { careTeamId } = args;
        return fetch(PATH + `/participant/care-team/${careTeamId}`,
        { 
            method: 'GET', 
            headers: getHeaders(),
        }  
        )
        .then(res => res.json())
    }
)

export const fetchParticipantById = createAsyncThunk(
    'participants/fetchParticipantById',
    async(args: FetchArgs) => {
        const { participantId } = args
        if(!participantId) return;
        return fetch(PATH + `/participant/${participantId}`,
        { 
            method: 'GET', 
            headers: getHeaders(),
        }  
        )
        .then(res => {
           return res.json()
        })
    }
)

export const putParticipant = createAsyncThunk(
    'participants/putParticipant',
    async(args: PutArgs) => {
        let requestBody = encodeRequestBody(args)
        return fetch(PATH + `/participant/${args.id}`,
            { 
                method: 'PUT', 
                headers: getHeaders(),
                body: requestBody
            }
        )
        .then(res => res.json())
        .catch(res => res.json())
    }
)

export const postParticipant = createAsyncThunk(
    'participants/postParticipant',
    async(args: PostArgs) => {
        let requestBody = encodeRequestBody(args)
        return fetch(PATH + `/participant/`,
            { 
                method: 'POST', 
                headers: getHeaders(),
                body: requestBody
            }  
        )
        .then(res => res.json())
        .catch(res => res.json())
    }
)

export const removeParticipant = createAsyncThunk(
    'participants/removeParticipant',
    async(id: string) => {
        return fetch(PATH + `/participant/remove/${id}`,
            { 
                method: 'DELETE', 
                headers: getHeaders(),
            }  
        )
        .then(res => res.json())
        .catch(res => res.json())
    }
)

const initialState: DefaultState = {
    allParticipants: {
        pending: false,
        data: []
    },
    selectedParticipant: {
        pending: false,
        data: null
    },
    participantWearables: {
        pending: false,
        data: []
    }
}

const participantsSlice = createSlice({
    name: 'participants',
    initialState,
    reducers: {
        resetParticipantsState(state) {
            state.allParticipants.data = []
            state.selectedParticipant.data = null
        },
        selectParticipantById(state, action) {
            let participant: any = state.allParticipants.data.find(participant => {
                return participant.id === action.payload
            })
            if(participant)
            state.selectedParticipant.data = participant
        },
        removeSelectedParticipant(state) {
            state.selectedParticipant.data = null
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchParticipantsByUserId.pending, (state) => {
            state.allParticipants.pending = true
        })
        builder.addCase(fetchParticipantsByUserId.fulfilled, (state, action) => {
            state.allParticipants.data = action.payload.participants || []
            state.allParticipants.pending = false
        })
        builder.addCase(fetchParticipantWearablesByUserId.pending, (state) => {
            state.participantWearables.pending = true
        })
        builder.addCase(fetchParticipantWearablesByUserId.fulfilled, (state, action) => {
            state.participantWearables.data = action.payload
            state.participantWearables.pending = false
        })
        builder.addCase(fetchParticipantsByOrganizationId.pending, (state) => {
            state.allParticipants.pending = true
        })
        builder.addCase(fetchParticipantsByOrganizationId.fulfilled, (state, action) => {
            state.allParticipants.data = action.payload.participants || []
            state.allParticipants.pending = false
        })
        builder.addCase(fetchParticipantsByCareTeamId.pending, (state) => {
            state.allParticipants.pending = true
        })
        builder.addCase(fetchParticipantsByCareTeamId.fulfilled, (state, action) => {
            state.allParticipants.data = action.payload.participants || []
            state.allParticipants.pending = false
        })
        builder.addCase(fetchParticipantById.pending, (state) => {
            state.selectedParticipant.pending = true
        })
        builder.addCase(fetchParticipantById.fulfilled, (state, action) => {
            state.selectedParticipant.data = action.payload
            state.selectedParticipant.pending = false
        })
        builder.addCase(putParticipant.pending, (state) => {
            state.allParticipants.pending = true
        })
        builder.addCase(putParticipant.fulfilled, (state) => {
            state.allParticipants.pending = false
        })
        builder.addCase(postParticipant.pending, (state) => {
            state.allParticipants.pending = true
        })
        builder.addCase(postParticipant.fulfilled, (state) => {
            state.allParticipants.pending = false
        })
        builder.addCase(removeParticipant.pending, (state) => {
            state.allParticipants.pending = true
        })
        builder.addCase(removeParticipant.fulfilled, (state) => {
            state.allParticipants.pending = false
        })
    }
})

export const { 
    resetParticipantsState, 
    removeSelectedParticipant, 
    selectParticipantById 
} = participantsSlice.actions;

export default participantsSlice.reducer;