import { CircularProgress } from "@mui/material"

interface Props {
    message?: string
};

export const Loading: React.FC<Props> = ({message}) => {
    return (
        <>
        {
            <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '8em',
                alignItems: 'center',
                justifyContent: 'center'
            }}
            >
                <CircularProgress/>
                {
                    message && 
                    <p
                    style={{
                        margin: '1em 0'
                    }}
                    >
                        {message}
                    </p>
                }
            </div>
        }
        </>
    )
};