import Paper from "@mui/material/Paper";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import { AcceptRejectModal } from "../../../components/Modals/AcceptRejectModal";
import AddIcon from "@mui/icons-material/Add";
import { OrganizationSelection } from "../../../components/ComboBox/OrganizationSelection";
import {
  deselectCareTeam,
  getCareTeams,
  selectCareTeam,
} from "../../../redux/careTeams/careTeamsHelpers";
import { CareTeamSelection } from "../../../components/ComboBox/CareTeamSelection";
import { useCallback, useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  Roles,
  useCareTeams,
  useOrganizationId,
  useOrganizations,
  useRoles,
  useWearables,
} from "../../../redux/hooks";
import {
  deleteWearable,
  deselectWearables,
  getWearableTypes,
  getWearablesByCareTeamId,
} from "../../../redux/wearables/wearablesHelpers";
import { Loading } from "../../../components/Loading/Loading";
import { getLatestReport } from "../../../redux/reports/reportsHelpers";
import { WearableConnectionModal } from "../../../components/Modals/WearableConnectionModal";
import { store } from "../../../redux/store";
import { resetReportsState } from "../../../redux/reports/reportsSlice";

export interface WearablesJoin {
  id: string;
  participant_id: string;
  participant_code: string;
  active_from: string;
  active_to: string;
  identifier: string;
  wearable_type_id: string;
}

interface Params {
  previousId: string;
}

export const WearablesTable: React.FC = () => {
  let { url } = useRouteMatch();
  let { previousId } = useParams<Params>();
  const { data, emptyResult, pending } = useWearables().allWearables;
  const wearableTypes = useWearables().wearableTypes.data;
  const selectedCareTeam = useCareTeams().selectedCareTeam.data;
  const selectedOrganization = useOrganizations().selectedOrganization.data;
  const [, authorities] = useRoles(Roles.organization);
  const organizationId = useOrganizationId();
  const [careTeamId, setCareTeamId] = useState<string | null>(null);
  const [wearables, setWearables] = useState<WearablesJoin[]>([]);
  const [wearableModalOpen, setWearableModalOpen] = useState(false);

  useEffect(() => {
    if (previousId) setCareTeamId(previousId);
  }, [previousId]);

  useEffect(() => {
    let wearables = [...data] as unknown[];
    let _wearables = [...wearables] as WearablesJoin[];
    setWearables(_wearables);
  }, [data]);

  const handleOrganizationSelection = useCallback(() => {
    deselectCareTeam();
    deselectWearables();
    getCareTeams(organizationId);
  }, [organizationId]);

  const handleCareTeamSelection = useCallback((id: string) => {
    selectCareTeam(id);
    getWearablesByCareTeamId(id);
  }, []);

  useEffect(() => {
    if (careTeamId) handleCareTeamSelection(careTeamId);
  }, [careTeamId, handleCareTeamSelection]);

  useEffect(() => {
    getWearableTypes();
  }, []);

  return (
    <>
      <WearableConnectionModal
        isOpen={wearableModalOpen}
        callBack={() => setWearableModalOpen(false)}
      />
      <p style={{ marginBottom: "0.5em", fontSize: 24 }}>Wearables</p>
      <div style={styles.combosOneLine}>
        <div style={styles.comboWrapper}>
          <OrganizationSelection callback={handleOrganizationSelection} />
        </div>
        <div style={styles.comboWrapper}>
          <CareTeamSelection callback={(id: string) => setCareTeamId(id)} />
        </div>
      </div>
      {wearables.length === 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            padding: "2em 0 0 0",
          }}
        >
          {selectedOrganization &&
            selectedCareTeam.length === 0 &&
            !emptyResult && <p>Please select a care team.</p>}
        </div>
      )}
      {pending && <Loading />}
      {selectedCareTeam.length > 0 && !pending && (
        <TableContainer sx={{ marginTop: "1.5em" }} component={Paper}>
          <Table aria-label={"Data table"}>
            <TableHead>
              <TableRow>
                <TableCell>Participant Code</TableCell>
                <TableCell>Wearable Type</TableCell>
                {/* <TableCell >Identifier</TableCell> */}
                <TableCell>Active From</TableCell>
                <TableCell>Active To</TableCell>
                {/* <TableCell >Is Active</TableCell> */}
                <TableCell sx={{ width: "2em" }}></TableCell>
                <TableCell sx={{ width: "2em" }}></TableCell>
                <TableCell sx={{ width: "2em" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {wearables.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{
                    "&:hover": {
                      background: "#efefef",
                    },
                  }}
                >
                  <TableCell>{row.participant_code}</TableCell>
                  <TableCell>
                    {
                      wearableTypes.filter(
                        (w) => w.id === row.wearable_type_id
                      )[0].name
                    }
                  </TableCell>
                  {/* <TableCell >{row.identifier}</TableCell> */}
                  <TableCell>{row.active_from}</TableCell>
                  <TableCell>{row.active_to}</TableCell>
                  {/* <TableCell >{row.is_active ? 'True': 'False'}</TableCell> */}

                  <TableCell align="center">
                    {(
                    // (authorities.includes("read") &&
                      // Only allow status checking for entries
                      // which are not expired
                      (new Date(row.active_to).getTime() >
                        new Date().getTime()) ||
                      !row.active_to) && (
                        <Button
                          variant="contained"
                          onClick={async () => {
                            store.dispatch(resetReportsState());
                            getLatestReport(row.participant_id);
                            setWearableModalOpen(true);
                          }}
                        >
                          Check
                        </Button>
                      )}
                  </TableCell>
                  <TableCell align="center">
                    {authorities.includes("update") &&
                      wearableTypes.filter(
                        (w) => w.id === row.wearable_type_id
                      )[0].name === "Samsung Galaxy" && (
                        <Link
                          to={`${url}/edit/${row.id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <Button variant="contained">Edit</Button>
                        </Link>
                      )}
                  </TableCell>
                  <TableCell align="center">
                    {authorities.includes("delete") &&
                      wearableTypes.filter(
                        (w) => w.id === row.wearable_type_id
                      )[0].name === "Samsung Galaxy" && (
                        <AcceptRejectModal
                          openLabel="Delete"
                          openColor="red"
                          acceptLabel="Yes"
                          acceptColor="red"
                          rejectLabel="No"
                          message="Are you sure you want to delete this record?"
                          callBack={() => deleteWearable(row.id)}
                        />
                      )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              height: "auto",
            }}
          >
            {authorities.includes("write") && (
              <Link to={`${url}/create/${careTeamId}`}>
                <Button
                  variant="contained"
                  onClick={() => {}}
                  sx={{
                    margin: "1em",
                    paddingRight: "0.75em",
                  }}
                >
                  New
                  <AddIcon
                    sx={{
                      fontSize: "1.3em",
                      marginLeft: "0.3em",
                      width: "1.35em",
                    }}
                  />
                </Button>
              </Link>
            )}
          </div>
        </TableContainer>
      )}
    </>
  );
};

const styles = {
  comboWrapper: {
    marginRight: "1em",
  },
  combosOneLine: {
    display: "flex",
  },
};
