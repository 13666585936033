import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { DB_ENDPOINT } from '../db'
import { DefaultState, FetchArgs } from './interfaces'
import { store } from '../store'

const ROUTE = '/notification-management'
const PATH = DB_ENDPOINT + ROUTE
// const PATH = "https://api.dev.awakelabs.com" + ROUTE

const getHeaders = () => {
    if(!store.getState().tokens.accessToken) return;

    var token = store.getState().tokens.accessToken;
    var headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    return headers;
}

export const fetchNotificationsByRange = createAsyncThunk(
    'notifications/fetchNotificationsByRange',
    async(args: FetchArgs) => {
        const { participantId, startAt, endAt } = args;
        return fetch(PATH + `/response/dashboard/query/list?participantId=${participantId}&startAt=${startAt}&endAt=${endAt}`,
        { 
            method: 'GET', 
            headers: getHeaders(),
        }  
        )
        .then(res => res.json());
    }
)

const initialState: DefaultState = {
    allNotifications: {
        pending: false,
        data: []
    }
};

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        resetNotificationsState(state) {
            state.allNotifications.data = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchNotificationsByRange.pending, (state) => {
            state.allNotifications.pending = true;
        })
        builder.addCase(fetchNotificationsByRange.fulfilled, (state, action) => {
            state.allNotifications.data = action.payload;
            state.allNotifications.pending = false;
        })
    }
})

export const { resetNotificationsState } = notificationsSlice.actions;

export default notificationsSlice.reducer;