import { useEffect, useState } from "react"
import { Site } from "../../interfaces/Site"
import { Roles, useOrganizations, useRoles, useSites } from "../../redux/hooks"
import { getSitesByOrganizationId } from "../../redux/sites/sitesHelpers"
import { ComboBox, Options } from "./ComboBox"

interface Props {
    callback: (id: string) => void;
    defaultId?: string;
}

export const SiteSelection: React.FC<Props> = ({callback, defaultId}) => {
    const { allSites } = useSites()
    const { selectedOrganization } = useOrganizations()
    const [siteId, setSiteId] = useState<null | string>(null)
    const [organizationAccess] = useRoles(Roles.organization)
    const [dropDownOptions, setDropDownOptions] = useState([{label: "None", value: "none"}])
    const [defaultValue, setDefaultValue] = useState<Options>({label: "None", value: "none"})

    useEffect(() => {
        let site = allSites.data.find(site => site.id === defaultId)
        if(site)
        setDefaultValue({
            label: `${site.name} (${site.code})`,
            value: site.id
        })
    },[defaultId, allSites.data])

    useEffect(() => {
        if(selectedOrganization.data) {
            getSitesByOrganizationId(selectedOrganization.data.id)
        }
    },[selectedOrganization.data])

    useEffect(() => {
        if(organizationAccess && siteId) {
            callback(siteId);
        }
    },[organizationAccess, siteId, callback])

    // Create options for drop down list
    useEffect(() => {
        if(allSites.data.length > 0) {
            let options = allSites.data.reduce((acc: any[], site: Site) => {
                    acc.push({
                        label: `${site.name} (${site.code})`,
                        value: site.id
                    })
                    return acc
                },[])
            setDropDownOptions(options)
        }
    },[allSites.data])

    const selectDropDown = (selection: Options | null) => {
        if(selection?.value) {
            setSiteId(selection.value);
        }
    }

    return (
        <>
        {
            organizationAccess &&
            <div>
                <p style={{marginBottom: '1em'}}>Select a Site:</p>
                <ComboBox 
                    label={'Site'} 
                    options={dropDownOptions} 
                    callback={selectDropDown}
                    defaultValue={defaultValue}
                />
            </div>
        }
        </>
    )
}