import ReactApexChart from "react-apexcharts";
import './ScatterPlot.css'

type charttype = "scatter";
var chartType: charttype = "scatter";
type zoomtype = "xy";
var zoomType: zoomtype = "xy";
type datetime = "datetime";
var dateTime : datetime = "datetime"

interface opts {
    series: any,
    seriesIndex: any,
    dataPointIndex: any,
    w: any
}
    
    var divStyle= `
    background-color: white;
    padding: 0.5em;
    `

export const ScatterPlot: React.FC = () => {
    
       const series = [{
            name: 'Participant 1',
            data: [
              // [new Date('15 Jan 2022 12:00 GMT').getTime()],
              {x: new Date('15 Jan 2022 13:00 GMT').getTime(), y: 100, emotion: 'High', fillColor: '#FF0000'},
              {x: new Date('15 Jan 2022 14:00 GMT').getTime(), y: 0, emotion: 'High', fillColor: 'green'},
              {x: new Date('15 Jan 2022 15:00 GMT').getTime(), y: 50, emotion: 'High', fillColor: 'yellow'},
              {x: new Date('15 Jan 2022 16:00 GMT').getTime(), y: 80, emotion: 'High', fillColor: 'orange'},
              {x: new Date('15 Jan 2022 17:00 GMT').getTime(), y: 100, emotion: 'High', fillColor: 'red'},
                // [new Date('11 Feb 2022 15:00 GMT').getTime(), 20, 'Low'],
                // [new Date('11 Feb 2022 15:10 GMT').getTime(), 25, 'Low'],
                // [new Date('11 Feb 2022 17:00 GMT').getTime(), 50, 'Medium'],
                // [new Date('11 Feb 2022 17:30 GMT').getTime(), 90, 'High'],
                // [new Date('12 Feb 2022 17:00 GMT').getTime(), 27, 'Low'],
                // [new Date('12 Feb 2022 17:10 GMT').getTime(), 40, 'Medium'],
                // [new Date('13 Feb 2022 GMT').getTime(), 60, 'Medium'],
                // [new Date('13 Feb 2022 GMT').getTime(), 80, 'High'],
                // [new Date('13 Feb 2022 GMT').getTime(), 100, 'High'],
            ]
          },
        //   {
        //     name: 'Participant 2',
        //     data: [[new Date('11 Feb 2022 GMT').getTime(), 25]]
        //   },
        //   {
        //     name: 'Participant 3',
        //     data: [[new Date('11 Feb 2022 GMT').getTime(), 30]]
        //   }
        ]

        const options = {
            tooltip: {
                custom: function(opts: opts) {
                    let data = opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex]
                    let anxietyLevel = opts.series[opts.seriesIndex][opts.dataPointIndex]
                    return (
                        `<div style='${divStyle}'>
                        <span>
                        Anxiety Level: ${anxietyLevel}<br />
                        Strong Emotion: ${data.emotion}<br />
                        Time: ${new Date(data.x)}
                        </span>
                        </div>`
                        )
                    }
                },
          chart: {
            height: 350,
            type: chartType,
            zoom: {
              type: zoomType
            },
            toolbar: {
                tools: {
                    download: false
                }
            },
          },
          dataLabels: {
            enabled: false
          },
          grid: {
            xaxis: {
              lines: {
                show: true
              }
            },
            yaxis: {
              lines: {
                show: true
              }
            },
          },
          xaxis: {
            type: dateTime,
          },
          yaxis: {
            max: 100
          }
        }
      
        return (
          <div className="scatter-chart-wrapper">
            <div className="scatter-chart-anxiety-legend">
            <p>Anxiety Level</p>
            </div>
            <div id="chart" className="scatter-plot">
                <ReactApexChart 
                    options={options} 
                    series={series} 
                    type="scatter" 
                    height={350} 
                />
            </div>
            <div className="scatter-chart-time-legend">
              <p>Date / Time</p>
            </div>
          </div>
        )
      
};
